<template>
  <ion-page>
    <HeaderBar></HeaderBar>

    <ion-content overflow-scroll="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title class="case-title">
            About Us
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-text class="md-text"> The OphthoRuler app was developed by Jeremy Moreau and Étienne Bénard-Séguin as part of the Calgary Ophthalmology Innovation Lab (COIL) in the Section of Ophthalmology, University of Calgary.</ion-text>


            <div class='md-text' id='main-about-block'>
              <h1> Team </h1>
              <ion-list>
                  <ion-item class='aboutus-avatar-item ion-no-padding' lines='none'>
                    <ion-avatar class="aboutus-avatar"><img src="/img/aboutus/aboutus-moreau.jpg"/></ion-avatar>
                    <ion-label text-wrap class='aboutus-label'>
                       <h3 class="aboutus-title">  Jeremy Moreau, MD, PhD </h3>
                       <p class="aboutus-subtitle"> Ophthalmology Resident </p>
                       <p class="aboutus-subtitle"> University of Calgary </p>
                    </ion-label>
                  </ion-item>
                  <ion-item class='aboutus-avatar-item ion-no-padding' lines='none'>
                    <ion-avatar class="aboutus-avatar"><img src="/img/aboutus/aboutus-benard-seguin.jpg"/></ion-avatar>
                    <ion-label text-wrap class='aboutus-label'>
                       <h3 class="aboutus-title">Étienne Bénard-Séguin, MD </h3>
                       <p class="aboutus-subtitle"> Neuro-Ophthalmology Fellow </p>
                       <p class="aboutus-subtitle"> Emory University </p>
                    </ion-label>
                  </ion-item>
              </ion-list>

              <!-- <h3> Staff Supervisors </h3> -->
              <ion-list>

                  <ion-item class='aboutus-avatar-item ion-no-padding' lines='none'>
                    <ion-avatar class="aboutus-avatar"><img src="/img/aboutus/aboutus-plemel.jpg"/></ion-avatar>
                    <ion-label text-wrap class='aboutus-label'>
                       <h3 class="aboutus-title"> David Plemel, MD </h3>
                       <p class="aboutus-subtitle"> Oculoplastic Surgery </p>
                       <p class="aboutus-subtitle"> Western University </p>
                    </ion-label>
                  </ion-item>

                  <ion-item class='aboutus-avatar-item ion-no-padding' lines='none'>
                    <ion-avatar class="aboutus-avatar"><img src="/img/aboutus/aboutus-ashenhurst.jpg"/></ion-avatar>
                    <ion-label text-wrap class='aboutus-label'>
                       <h3 class="aboutus-title"> Michael Ashenhurst, MD </h3>
                       <p class="aboutus-subtitle"> Oculoplastic Surgery </p>
                       <p class="aboutus-subtitle"> University of Calgary </p>
                    </ion-label>
                  </ion-item>

                  <ion-item class='aboutus-avatar-item ion-no-padding' lines='none'>
                    <ion-avatar class="aboutus-avatar"><img src="/img/aboutus/aboutus-weis.jpg"/></ion-avatar>
                    <ion-label text-wrap class='aboutus-label'>
                       <h3 class="aboutus-title"> Ezekiel Weis, MD, MPH </h3>
                       <p class="aboutus-subtitle"> Oculoplastic Surgery </p>
                       <p class="aboutus-subtitle"> University of Calgary </p>
                    </ion-label>
                  </ion-item>

                  <ion-item class='aboutus-avatar-item ion-no-padding' lines='none'>
                    <ion-avatar class="aboutus-avatar"><img src="/img/aboutus/aboutus-kherani.jpg"/></ion-avatar>
                    <ion-label text-wrap class='aboutus-label'>
                       <h3 class="aboutus-title"> Femida Kherani, MD </h3>
                       <p class="aboutus-subtitle"> Paediatric Oculoplastic Surgery </p>
                       <p class="aboutus-subtitle"> University of Calgary </p>
                    </ion-label>
                  </ion-item>

                  <ion-item class='aboutus-avatar-item ion-no-padding' lines='none'>
                    <ion-avatar class="aboutus-avatar"><img src="/img/aboutus/aboutus-punja.jpg"/></ion-avatar>
                    <ion-label text-wrap class='aboutus-label'>
                       <h3 class="aboutus-title"> Karim Punja, MD </h3>
                       <p class="aboutus-subtitle"> Oculoplastic Surgery </p>
                       <p class="aboutus-subtitle"> University of Calgary </p>
                    </ion-label>
                  </ion-item>

                  <ion-item class='aboutus-avatar-item ion-no-padding' lines='none'>
                    <ion-avatar class="aboutus-avatar"><img src="/img/aboutus/aboutus-ting.jpg"/></ion-avatar>
                    <ion-label text-wrap class='aboutus-label'>
                       <h3 class="aboutus-title"> Andrew Ting, MD </h3>
                       <p class="aboutus-subtitle"> Oculoplastic Surgery </p>
                       <p class="aboutus-subtitle"> University of Alberta </p>
                    </ion-label>
                  </ion-item>

                  <ion-item class='aboutus-avatar-item ion-no-padding' lines='none'>
                    <ion-avatar class="aboutus-avatar"><img src="/img/aboutus/aboutus-costello.jpg"/></ion-avatar>
                    <ion-label text-wrap class='aboutus-label'>
                       <h3 class="aboutus-title">  Fiona Costello, MD </h3>
                       <p class="aboutus-subtitle"> Neuro-Ophthalmology </p>
                       <p class="aboutus-subtitle"> University of Calgary </p>
                    </ion-label>
                  </ion-item>

              </ion-list>
            </div>


          <ion-text class="md-text"> </ion-text>
        </ion-card-content>
      </ion-card>
    </ion-content>

    <!-- <Footer></Footer> -->
  </ion-page>
</template>

<script lang='js'>
import { defineComponent } from "vue";
import HeaderBar from "./HeaderBar.vue";
import {IonPage, IonContent, IonCardTitle, IonCardHeader, IonText, IonAvatar, IonLabel, IonItem, IonList, IonCardContent, IonCard} from '@ionic/vue';
export default defineComponent({
  name: "SideMenuInstall",
  components: {
    HeaderBar,
    IonPage,
    IonContent,
    IonCardTitle,
    IonCardHeader,
    IonText,
    IonAvatar,
    IonLabel,
    IonItem,
    IonList,
    IonCardContent,
    IonCard
  },
  data() {
    return {
      tippyTouchOptions: ['hold', 300],
      langvalue: 'en'
    }
  },
  mounted() {
    // disable context menu on nav icons (to not interfere with tooltips)
    [...document.querySelectorAll(".nav-icons")].forEach( el => 
    el.addEventListener('contextmenu', e => e.preventDefault())
    );
  },

  methods: {
    gotoPage(pageName) {
      this.$router.push({
        name: pageName,
      });
    },

  },
})
</script>

<style scoped>
.instructions-card {
  margin-top: 10px;
}
.header-title {
  padding-top: 0px;
  padding-bottom: 0px;
}
#main-about-block {
  padding-top: 10px;
}
.aboutus-avatar-item {
  padding-top: 5px;
  padding-bottom: 10px;
}
.aboutus-avatar {
  height: 100px;
  width: 100px;
  margin-right: 10px;
}
.aboutus-label {
  margin-top: 0px;
  margin-bottom: 0px;
}
h1 {
 font-size: 1.3rem;
 font-weight: bold;
 padding-top: 0.6rem;
 padding-bottom: 0.5rem;
 hyphens: none;
}


@media (prefers-color-scheme: dark) {
  .aboutus-avatar-item {
      background-color: rgb(28, 28, 29);
  }
  .aboutus-avatar-item {
      --ion-item-background: rgb(28, 28, 29);
  }
  .aboutus-avatar-item {
      background-color: rgb(30, 30, 30);
  }
  .aboutus-avatar-item {
      --ion-item-background: rgb(30, 30, 30);
  }
}
</style>
<template>
    <ion-page>
        <HeaderBar></HeaderBar>

        <ion-content overflow-scroll="true">
            <ion-list>
                <ion-item>
                    <ion-select label="Measure" value="MRD1" v-model="measureInput" @ionChange="getZScores">
                        <ion-select-option value="MRD1">MRD1</ion-select-option>
                        <ion-select-option value="MRD2">MRD2</ion-select-option>
                        <ion-select-option value="PFH">PFH</ion-select-option>
                        <ion-select-option value="PFW">PFW</ion-select-option>
                        <ion-select-option value="ICD">ICD</ion-select-option>
                        <ion-select-option value="OCD">OCD</ion-select-option>
                        <ion-select-option value="IPD">IPD</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item>
                    <ion-select label="Sex" value="M" v-model="sexInput" @ionChange="getZScores">
                        <ion-select-option value="M">Male</ion-select-option>
                        <ion-select-option value="F">Female</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item @click="$refs.ageYearsInputBox.$el.querySelector('input').select()">
                    <ion-input label="Age (years)" class="ion-text-right" type="tel" value=0 v-model="ageInput"
                        @ionChange="getZScores" @click="$event.target.select()" ref="ageYearsInputBox">
                    </ion-input>
                </ion-item>
                <ion-item @click="$refs.ageMonthsInputBox.$el.querySelector('input').select()">
                    <ion-input label="Age (months)" class="ion-text-right" type="tel" value=0 v-model="ageMonthsInput"
                        @ionChange="getZScores" @click="$event.target.select()" ref="ageMonthsInputBox">
                    </ion-input>
                </ion-item>

            </ion-list>

            <ion-card>


                <ion-card-content id="top-blurb">
                    <ion-row class="ion-text-center ion-justify-content-center">
                        <ion-text class="ion-text-center">
                            Comparing <strong>{{ measureInput }}</strong> to norm for a {{ ageInput }}y{{ ageMonthsInput }}m old {{ sexInput }}
                        </ion-text>
                    </ion-row>
                    

            <!-- Display results -->
            <!-- OD -->
            <ion-grid class="ion-padding-bottom" v-if="['MRD1', 'MRD2', 'PFH', 'PFW'].includes(measureInput)">
                <ion-row class="ion-align-items-center">
                    <ion-col size="3">
                        <span class='side-title' id='side-title-OD'>OD</span>
                    </ion-col>
                    <ion-col>
                        <strong>Meas.</strong>
                    </ion-col>
                    <ion-col>
                        <strong>Mean</strong>
                    </ion-col>
                    <!-- <ion-col>
                        <strong>SD</strong>
                    </ion-col> -->
                    <ion-col>
                        <strong>Delta</strong>
                    </ion-col>
                    <ion-col>
                        <strong>Z (SD)</strong>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-align-items-center" v-for="(value, key) in resultsData" :key="key">
                    <ion-col :id="`click-trigger-OD-${key}`" size="3">
                        <ion-text :style="{ color: 'var(--ion-color-primary)' }">
                            {{ value['population'] }} <br> ({{ value['age_label'] }})
                        </ion-text>
                    </ion-col>
                    <ion-popover :trigger="`click-trigger-OD-${key}`" trigger-action="click">
                        <ion-content class="ion-padding">
                            <ion-row>
                                <a :href="value['doi']" target="_blank">{{ value['source'] }}</a><br>
                            </ion-row>
                            <ion-row class="padding-top-5">
                                <ion-text><i>N = {{ value['n'] }}</i></ion-text>
                            </ion-row>
                            <ion-row class="padding-top-5 ion-padding-bottom">
                                <ion-text>{{ value['notes'] }}</ion-text>
                            </ion-row>
                            <ion-row class="ion-justify-content-center">
                                <ion-button @click="getAgeRangeData(value['OD']['x_raw_score'], (Number(this.ageInput) + Number(this.ageMonthsInput) / 12), value['source'], value['doi'])">
                                    <ion-icon :icon="trendingUpOutline" slot="start"></ion-icon>
                                    <ion-label slot="end">Growth Chart</ion-label>
                                </ion-button>
                            </ion-row>
                        </ion-content>
                    </ion-popover>

                    <ion-col>
                        {{ value['OD']['x_raw_score'] }} <span v-if="value['OD']['x_raw_score']"></span>
                    </ion-col>
                    <ion-col>
                        {{ value['OD']['u_pop_mean'] }} <span v-if="value['OD']['u_pop_mean']"></span>
                    </ion-col>
                    <!-- <ion-col>
                        {{ value['OD']['s_pop_sd'] }} <span v-if="value['OD']['s_pop_sd']"></span>
                    </ion-col> -->
                    <ion-col>
                        {{ value['OD']['delta'] }} <span v-if="value['OD']['delta']"></span>
                    </ion-col>
                    <ion-col>
                        <ion-text :class="{
                            'blue-text': Math.abs(value['OD']['z_score']) <= 1.0,
                            'green-text': Math.abs(value['OD']['z_score']) > 1.0 && Math.abs(value['OD']['z_score']) <= 2.0,
                            'red-text': Math.abs(value['OD']['z_score']) > 2.0
                        }">
                            <strong> {{ value['OD']['z_score'] }} </strong><span v-if="value['OD']['z_score']"></span>
                        </ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <!-- OS -->
            <ion-grid class="ion-padding-bottom" v-if="['MRD1', 'MRD2', 'PFH', 'PFW'].includes(measureInput)">
                <ion-row class="ion-align-items-center">
                    <ion-col size="3">
                        <span class='side-title' id='side-title-OS'>OS</span>
                    </ion-col>
                    <ion-col>
                        <strong>Meas.</strong>
                    </ion-col>
                    <ion-col>
                        <strong>Mean</strong>
                    </ion-col>
                    <!-- <ion-col>
                        <strong>SD</strong>
                    </ion-col> -->
                    <ion-col>
                        <strong>Delta</strong>
                    </ion-col>
                    <ion-col>
                        <strong>Z (SD)</strong>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-align-items-center" v-for="(value, key) in resultsData" :key="key">
                    <ion-col :id="`click-trigger-OS-${key}`" size="3">
                        <ion-text :style="{ color: 'var(--ion-color-primary)' }">
                            {{ value['population'] }} <br> ({{ value['age_label'] }})
                        </ion-text>
                    </ion-col>
                    <ion-popover :trigger="`click-trigger-OS-${key}`" trigger-action="click">
                        <ion-content class="ion-padding">
                            <ion-row>
                                <a :href="value['doi']" target="_blank">{{ value['source'] }}</a><br>
                            </ion-row>
                            <ion-row class="padding-top-5">
                                <ion-text><i>N = {{ value['n'] }}</i></ion-text>
                            </ion-row>
                            <ion-row class="padding-top-5 ion-padding-bottom">
                                <ion-text>{{ value['notes'] }}</ion-text>
                            </ion-row>
                            <ion-row class="ion-justify-content-center">
                                <ion-button @click="getAgeRangeData(value['OS']['x_raw_score'], (Number(this.ageInput) + Number(this.ageMonthsInput) / 12), value['source'], value['doi'])">
                                    <ion-icon :icon="trendingUpOutline" slot="start"></ion-icon>
                                    <ion-label slot="end">Growth Chart</ion-label>
                                </ion-button>
                            </ion-row>
                        </ion-content>
                    </ion-popover>

                    <ion-col>
                        {{ value['OS']['x_raw_score'] }} <span v-if="value['OS']['x_raw_score']"></span>
                    </ion-col>
                    <ion-col>
                        {{ value['OS']['u_pop_mean'] }} <span v-if="value['OS']['u_pop_mean']"></span>
                    </ion-col>
                    <!-- <ion-col>
                        {{ value['OS']['s_pop_sd'] }} <span v-if="value['OS']['s_pop_sd']"></span>
                    </ion-col> -->
                    <ion-col>
                        {{ value['OS']['delta'] }} <span v-if="value['OS']['delta']"></span>
                    </ion-col>
                    <ion-col>
                        <ion-text :class="{
                            'blue-text': Math.abs(value['OS']['z_score']) <= 1.0,
                            'green-text': Math.abs(value['OS']['z_score']) > 1.0 && Math.abs(value['OS']['z_score']) <= 2.0,
                            'red-text': Math.abs(value['OS']['z_score']) > 2.0
                        }">
                            <strong> {{ value['OS']['z_score'] }} </strong><span v-if="value['OS']['z_score']"></span>
                        </ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <!-- OU -->
            <ion-grid class="ion-padding-bottom" v-if="['ICD', 'OCD', 'IPD'].includes(measureInput)">
                <ion-row class="ion-align-items-center">
                    <ion-col size="3">
                        <span class='side-title'>OU</span>
                    </ion-col>
                    <ion-col>
                        <strong>Meas.</strong>
                    </ion-col>
                    <ion-col>
                        <strong>Mean</strong>
                    </ion-col>
                    <!-- <ion-col>
                        <strong>SD</strong>
                    </ion-col> -->
                    <ion-col>
                        <strong>Delta</strong>
                    </ion-col>
                    <ion-col>
                        <strong>Z (SD)</strong>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-align-items-center" v-for="(value, key) in resultsData" :key="key">
                    <ion-col :id="`click-trigger-OU-${key}`" size="3">
                        <ion-text :style="{ color: 'var(--ion-color-primary)' }">
                            {{ value['population'] }} <br> ({{ value['age_label'] }})
                        </ion-text>
                    </ion-col>
                    <ion-popover :trigger="`click-trigger-OU-${key}`" trigger-action="click">
                        <ion-content class="ion-padding">
                            <ion-row>
                                <a :href="value['doi']" target="_blank">{{ value['source'] }}</a><br>
                            </ion-row>
                            <ion-row class="padding-top-5">
                                <ion-text><i>N = {{ value['n'] }}</i></ion-text>
                            </ion-row>
                            <ion-row class="padding-top-5 ion-padding-bottom">
                                <ion-text>{{ value['notes'] }}</ion-text>
                            </ion-row>
                            <ion-row class="ion-justify-content-center">
                                <ion-button @click="getAgeRangeData(value['OU']['x_raw_score'], (Number(this.ageInput) + Number(this.ageMonthsInput) / 12), value['source'], value['doi'])">
                                    <ion-icon :icon="trendingUpOutline" slot="start"></ion-icon>
                                    <ion-label slot="end">Growth Chart</ion-label>
                                </ion-button>
                            </ion-row>
                        </ion-content>
                    </ion-popover>

                    <ion-col>
                        {{ value['OU']['x_raw_score'] }} <span v-if="value['OU']['x_raw_score']"></span>
                    </ion-col>
                    <ion-col>
                        {{ value['OU']['u_pop_mean'] }} <span v-if="value['OU']['u_pop_mean']"></span>
                    </ion-col>
                    <!-- <ion-col>
                        {{ value['OU']['s_pop_sd'] }} <span v-if="value['OU']['s_pop_sd']"></span>
                    </ion-col> -->
                    <ion-col>
                        {{ value['OU']['delta'] }} <span v-if="value['OU']['delta']"></span>
                    </ion-col>
                    <ion-col>
                        <ion-text :class="{
                            'blue-text': Math.abs(value['OU']['z_score']) <= 1.0,
                            'green-text': Math.abs(value['OU']['z_score']) > 1.0 && Math.abs(value['OU']['z_score']) <= 2.0,
                            'red-text': Math.abs(value['OU']['z_score']) > 2.0
                        }">
                            <strong> {{ value['OU']['z_score'] }} </strong><span v-if="value['OU']['z_score']"></span>
                        </ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
            
            <img src="/img/z_curve.svg" id="zcurve" alt="z curve" />

                     <!-- <br><br>
                    <span> {{ measuredData }}</span>
                    <br><br>
                    <span> {{ resultsData }}</span> -->


                </ion-card-content>
            </ion-card>

            <ion-text class="copyright" color="dark"
                >© 2024 Jeremy Moreau, MD, PhD<br>Section of Ophthalmology, University of Calgary
                <br>
                Use of this app is subject to <router-link to="/disclaimer">Disclaimer</router-link>
                <br>
                <span class="version">version {{ appVersion }}</span>
            </ion-text>
        </ion-content>
        
        <!-- growth chart ion-modal -->
        <ion-modal :is-open="showModal" @ionModalDidDismiss="showModal = false">
            <ion-header>
                <ion-toolbar>
                    <ion-title>Growth Chart</ion-title>
                    <ion-buttons slot="end">
                        <ion-button @click="showModal = false">Close</ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ion-card>
                    <ion-card-header>
                        <ion-card-title> {{ sourceLabel }}</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <div style="height: 400px; padding-bottom: 10px;">
                            <line-chart :ageRangeData="ageRangeData"></line-chart>
                        </div>
                        <ion-text class="ion-padding-top">
                            <p>Individual patient is represented as the red dot. Solid line represents the population mean. Shaded outlines are ± 1 and 2 standard deviations</p>
                            <p><strong>Reference:</strong>  <a :href="doiLabel" target="_blank">{{ doiLabel }}</a></p>
                        </ion-text>
                    </ion-card-content>
                </ion-card>
            </ion-content>
        </ion-modal>
    </ion-page>
</template>
  
<script lang='js'>
import { defineComponent } from "vue";
import HeaderBar from "./HeaderBar.vue";
import LineChart from './LineChart.vue';
import packageJson from '../../package.json';
import {
    IonPage, IonContent, IonSpinner,
    IonCardContent,
    IonCard,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonText,
    IonList,
    IonPopover,
    IonCol,
    IonRow,
    IonGrid,
    IonButton,
    IonModal,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonLabel,
    IonTitle,
    IonButtons,
    IonCardHeader,
    IonCardTitle
} from '@ionic/vue';
import { personCircleOutline, imageOutline, trendingUpOutline } from 'ionicons/icons';


export default defineComponent({
    name: "CompareToNorm",
    props: {
        registerIonPage: Function,
    },
    components: {
        HeaderBar,
        LineChart,
        IonPage,
        IonContent,
        IonCardContent,
        IonCard,
        IonItem,
        IonSelect,
        IonSelectOption,
        IonInput,
        IonText,
        IonList,
        IonPopover,
        IonCol,
        IonRow,
        IonGrid,
        IonButton,
        IonModal,
        IonIcon,
        IonHeader,
        IonToolbar,
        IonLabel,
        IonTitle,
        IonButtons,
        IonCardHeader,
        IonCardTitle
    },
    data() {
        return {
            appVersion: packageJson.version,
            measureInput: 'MRD1',
            sexInput: 'M',
            ageInput: 30,
            ageMonthsInput: 0,
            jsonData: [],
            sourceLabel: '',
            doiLabel: '',
            isLoading: false,
            measuredData: [],
            filteredData: [],
            ageRangeData: [],
            resultsData: [],
            showModal: false,
        }
    },
    setup() {
        return {
            personCircleOutline, imageOutline, trendingUpOutline
        }
    },
    mounted() {
        this.loadData()

        let dataOD = JSON.parse(this.$route.query.OD);
        let dataOS = JSON.parse(this.$route.query.OS);
        let dataOU = JSON.parse(this.$route.query.OU);
        this.measuredData = {
            'OD': dataOD,
            'OS': dataOS,
            'OU': dataOU
        }
        //console.log(this.measuredData)
    },
    methods: {
        gotoPage(pageName, cameraMode) {
            this.$router.push({
                name: pageName,
                params: { cameraMode: cameraMode },
            });
        },
        filterData(data, criteria) {
            // console.log('Data:', data);
            // console.log('Criteria:', criteria);
            return data.filter(item => {
                return Object.keys(criteria).every(key => {
                    if (key === 'age') {
                        // console.log('Item age_low:', item['age_low'], 'Item age_high:', item['age_high'], 'Criteria age:', criteria[key]);
                        return item['age_low'] <= criteria[key] && item['age_high'] > criteria[key];
                    }
                    return item[key] === criteria[key];
                });
            });
        },
        async loadData() {
            try {
                const response = await fetch('/data/norm_data.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                this.jsonData = data; // Set the loaded JSON data to the jsonData array
                
                this.getZScores()
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading = false; // Update isLoading flag when data loading is finished
                console.log('loading:', this.isLoading);
                //console.log(JSON.parse(JSON.stringify(this.jsonData)));
            }
        },
        getZScores() {
            // Clear resultsData
            this.resultsData = [];

            // filter data to display
            const allData = JSON.parse(JSON.stringify(this.jsonData))
            let filteredData = this.filterData(allData, {
                'sex': this.sexInput,
                'measure': this.measureInput,
                'age': Number(this.ageInput) + Number(this.ageMonthsInput) / 12,
            })
            //console.log(filteredData)

            for (let i = 0; i < filteredData.length; i++) {
                let item = filteredData[i]
                
                let u_pop_mean = item.mean || 0;
                let s_pop_sd = item.sd || 0;

                let x_raw_scoreOD = this.measuredData['OD'][item.measure] || 0;
                let x_raw_scoreOS = this.measuredData['OS'][item.measure] || 0;
                let x_raw_scoreOU = this.measuredData['OU'][item.measure] || 0;

                let deltaOD = x_raw_scoreOD - u_pop_mean;
                let deltaOS = x_raw_scoreOS - u_pop_mean;
                let deltaOU = x_raw_scoreOU - u_pop_mean;

                let z_scoreOD = s_pop_sd !== 0 ? (x_raw_scoreOD - u_pop_mean) / s_pop_sd : 0;
                let z_scoreOS = s_pop_sd !== 0 ? (x_raw_scoreOS - u_pop_mean) / s_pop_sd : 0;
                let z_scoreOU = s_pop_sd !== 0 ? (x_raw_scoreOU - u_pop_mean) / s_pop_sd : 0;

                this.resultsData[i] = {
                    'OD': {
                        'x_raw_score': parseFloat(x_raw_scoreOD.toFixed(1)),
                        'u_pop_mean': parseFloat(u_pop_mean.toFixed(1)),
                        's_pop_sd': parseFloat(s_pop_sd.toFixed(1)),
                        'delta': parseFloat(deltaOD.toFixed(1)),
                        'z_score': parseFloat(z_scoreOD.toFixed(1))
                    },
                    'OS': {
                        'x_raw_score': parseFloat(x_raw_scoreOS.toFixed(1)),
                        'u_pop_mean': parseFloat(u_pop_mean.toFixed(1)),
                        's_pop_sd': parseFloat(s_pop_sd.toFixed(1)),
                        'delta': parseFloat(deltaOS.toFixed(1)),
                        'z_score': parseFloat(z_scoreOS.toFixed(1))
                    },
                    'OU': {
                        'x_raw_score': parseFloat(x_raw_scoreOU.toFixed(1)),
                        'u_pop_mean': parseFloat(u_pop_mean.toFixed(1)),
                        's_pop_sd': parseFloat(s_pop_sd.toFixed(1)),
                        'delta': parseFloat(deltaOU.toFixed(1)),
                        'z_score': parseFloat(z_scoreOU.toFixed(1))
                    },
                    'population': item['population'] || '',
                    'age_range': item['age_range'] || '',
                    'age_label': item['age_label'] || '',
                    'n': item['n'] || '',
                    'notes': item['notes'] || '',
                    'source': item['source'] || '',
                    'doi': item['doi'] || '',
                }
            }
        },
        getAgeRangeData(x_raw_score, ageInput, source, doi) {
            let ageRangeData = this.jsonData.filter(item => {
                return item.measure === this.measureInput && item.sex === this.sexInput && item.source === source;
            });

            ageRangeData = ageRangeData.map(item => {
                return {
                ...item,
                raw_score: x_raw_score,
                age: ageInput
                };
            });
            //console.log(ageRangeData)
            this.ageRangeData = ageRangeData;
            this.sourceLabel = source;
            this.doiLabel = doi;
            this.showModal = true;
        },
    },
})
</script>
  
<style scoped>
#logo {
    margin: auto;
    padding-top: 20px;
    width: 180px;
}


.result-heading {
  color: #1a1a1a;
  font-weight: 400;
}
.result-subtitle {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.15rem;
  font-weight: 700;
}
.side-title {
    font-weight: 800;
    font-size: 1.2rem;
    color: #000000;
}
#side-title-OD {
    color: #FF0D00;
}
#side-title-OS {
    color: #00F2FF;
}

.main-content {
    padding: 10px;
}

.btn-group {
    padding: 10px;
}

.copyright {
    display: block;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    text-align: center;
    hyphens: none;
    padding-bottom: 20px;
}

.version {
    font-size: 0.9rem;
    font-family: monospace;
}

.blue-text {
    color: rgb(83, 131, 187);
}
.green-text {
    color: rgb(96, 187, 163);
}
.red-text {
    color: rgb(186, 98, 120);
}
.padding-top-5 {
    padding-top: 5px;
}
</style>
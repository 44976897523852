<template>
  <ion-page>
    <HeaderBar></HeaderBar>

    <ion-content overflow-scroll="true">
      <ion-card>
        <video id="input_video" webkit-playsinline playsinline></video>  <!-- for input only, display:none -->
        <canvas id="output_canvas" :class="{ 'flip-horizontal': this.$route.query.cameraMode === 'user' }"></canvas> <!-- for display -->
        <!-- <canvas id="output_canvas2"></canvas> for display -->
        <ion-card-content> 
            <ion-row>
               <ion-col>
                    <ion-button expand="block" @click="recordResults(60)">
                        <ion-icon :icon="timerOutline" slot="start"></ion-icon><ion-label slot="end">Record 2s</ion-label>
                    </ion-button>
              </ion-col>
              <ion-col>
                    <ion-button expand="block" @click="recordResults(150)">
                        <ion-icon :icon="timerOutline" slot="start"></ion-icon><ion-label slot="end">Record 5s</ion-label>
                    </ion-button>
              </ion-col>
            </ion-row>

            <!-- Display sample count while recording -->
            <ion-row v-if="sampleCount" class="ion-text-center">
                <ion-col>
                <ion-text class="ion-text-center">
                    Recording sample {{ sampleCount }} of {{ sampleMax }}
                    </ion-text>
                </ion-col>
            </ion-row>

            

            <!-- <ion-row>
                <ion-col class="ion-text-center">
                    <ion-list lines='none'>
                        <ion-item>
                        <ion-label>High-Accuracy Mode</ion-label>
                            <ion-toggle
                            @ionChange="highAccuracyMode = $event.target.checked">
                             
                            </ion-toggle>
                        </ion-item>
                    </ion-list>
                </ion-col>
            </ion-row> -->
            
            <ion-row>
                <ion-col>
                    <div v-if="highAccuracyMode" class="ion-padding-bottom"></div>
                    <div id="picker"></div>
                </ion-col>
            </ion-row>
            <ion-row v-if="highAccuracyMode">
                <ion-list lines='none'>
                    <ion-radio-group :value="highAccModeSelected">
                        <ion-item>
                            <ion-label>Small post-it (47.6 x 34.9 mm)</ion-label>
                            <ion-radio slot="start" @ionFocus="setHighAccValues($event.target.value); highAccModeSelected='small'" value='small'></ion-radio>
                        </ion-item>

                        <ion-item>
                            <ion-label>Regular post-it (76 x 76 mm)</ion-label>
                            <ion-radio slot="start" @ionFocus="setHighAccValues($event.target.value); highAccModeSelected='regular'" value='regular'></ion-radio>
                        </ion-item>

                        <ion-item>
                            <ion-label>Custom</ion-label>
                            <ion-radio slot="start" @ionFocus="highAccModeSelected = 'custom'" value='custom'></ion-radio>
                            <ion-input aria-label="highAccW" class="ion-text-end" v-model="highAccW" type="number"></ion-input>
                            <span class="ion-padding-start">x</span>
                            <ion-input aria-label="highAccH" class="ion-text-end" v-model="highAccH" type="number"></ion-input>
                            <span>mm</span>
                        </ion-item>
                    </ion-radio-group>
                </ion-list>
            </ion-row>
            
            <!-- <ion-row>
                {{ this.$route.params.cameraMode }}
            </ion-row> -->

            

            <!-- Display results -->
            <!-- OD -->
            <!-- <ion-row>
                <ion-text class='result-subtitle'>OD</ion-text>
            </ion-row> -->
            <ion-grid class="ion-padding-bottom">
                <ion-row class="ion-align-items-center">
                    <ion-col>
                        <span class='side-title' id='side-title-OD'>OD</span>
                    </ion-col>
                    <ion-col>
                        <strong>Mean</strong>
                    </ion-col>
                    <ion-col>
                        <strong>Median</strong>
                    </ion-col>
                    <ion-col>
                        <strong>SD</strong>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <strong>MRD1</strong>
                    </ion-col>
                    <ion-col>
                        {{ mrd1OD['mean'] }} <span v-if="mrd1OD['mean']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ mrd1OD['median'] }} <span v-if="mrd1OD['median']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ mrd1OD['sd'] }} <span v-if="mrd1OD['sd']">mm</span>
                    </ion-col>
                </ion-row>
                <!-- <ion-row>
                    <ion-col>
                        <strong>MRD1x</strong>
                    </ion-col>
                    <ion-col>
                        {{ mrd1OD['mean'] }} <span v-if="mrd1OD['mean']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ mrd1OD['median'] }} <span v-if="mrd1OD['median']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ mrd1OD['sd'] }} <span v-if="mrd1OD['sd']">mm</span>
                    </ion-col>
                </ion-row> -->
                <ion-row>
                    <ion-col>
                        <strong>MRD2</strong>
                    </ion-col>
                    <ion-col>
                        {{ mrd2OD['mean'] }} <span v-if="mrd2OD['mean']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ mrd2OD['median'] }} <span v-if="mrd2OD['median']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ mrd2OD['sd'] }} <span v-if="mrd2OD['sd']">mm</span>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <strong>PF H</strong>
                    </ion-col>
                    <ion-col>
                        {{ pfhOD['mean'] }} <span v-if="pfhOD['mean']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ pfhOD['median'] }} <span v-if="pfhOD['median']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ pfhOD['sd'] }} <span v-if="pfhOD['sd']">mm</span>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <strong>PF W</strong>
                    </ion-col>
                    <ion-col>
                        {{ pfwOD['mean'] }} <span v-if="pfwOD['mean']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ pfwOD['median'] }} <span v-if="pfwOD['median']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ pfwOD['sd'] }} <span v-if="pfwOD['sd']">mm</span>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <strong>PF A</strong>
                    </ion-col>
                    <ion-col>
                        {{ pfaOD['mean'] }} <span v-if="pfaOD['mean']">mm<sup>2</sup></span>
                    </ion-col>
                    <ion-col>
                        {{ pfaOD['median'] }} <span v-if="pfaOD['median']">mm<sup>2</sup></span>
                    </ion-col>
                    <ion-col>
                        {{ pfaOD['sd'] }} <span v-if="pfaOD['sd']">mm<sup>2</sup></span>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <strong>CA</strong>
                    </ion-col>
                    <ion-col>
                        {{ canthalTiltOD['mean'] }} <span v-if="canthalTiltOD['mean']">°</span>
                    </ion-col>
                    <ion-col>
                        {{ canthalTiltOD['median'] }} <span v-if="pfaOD['median']">°</span>
                    </ion-col>
                    <ion-col>
                        {{ canthalTiltOD['sd'] }} <span v-if="pfaOD['sd']">°</span>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <!-- OS -->
            <!-- <ion-row>
                <ion-text class='result-subtitle'>OD</ion-text>
            </ion-row> -->
            <ion-grid class="ion-padding-bottom">
                <ion-row class="ion-align-items-center">
                    <ion-col>
                        <span class='side-title' id='side-title-OS'>OS</span>
                    </ion-col>
                    <ion-col>
                        <strong>Mean</strong>
                    </ion-col>
                    <ion-col>
                        <strong>Median</strong>
                    </ion-col>
                    <ion-col>
                        <strong>SD</strong>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <strong>MRD1</strong>
                    </ion-col>
                    <ion-col>
                        {{ mrd1OS['mean'] }} <span v-if="mrd1OS['mean']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ mrd1OS['median'] }} <span v-if="mrd1OS['median']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ mrd1OS['sd'] }} <span v-if="mrd1OS['sd']">mm</span>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <strong>MRD2</strong>
                    </ion-col>
                    <ion-col>
                        {{ mrd2OS['mean'] }} <span v-if="mrd2OS['mean']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ mrd2OS['median'] }} <span v-if="mrd2OS['median']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ mrd2OS['sd'] }} <span v-if="mrd2OS['sd']">mm</span>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <strong>PF H</strong>
                    </ion-col>
                    <ion-col>
                        {{ pfhOS['mean'] }} <span v-if="pfhOS['mean']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ pfhOS['median'] }} <span v-if="pfhOS['median']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ pfhOS['sd'] }} <span v-if="pfhOS['sd']">mm</span>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <strong>PF W</strong>
                    </ion-col>
                    <ion-col>
                        {{ pfwOS['mean'] }} <span v-if="pfwOS['mean']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ pfwOS['median'] }} <span v-if="pfwOS['median']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ pfwOS['sd'] }} <span v-if="pfwOS['sd']">mm</span>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <strong>PF A</strong>
                    </ion-col>
                    <ion-col>
                        {{ pfaOS['mean'] }} <span v-if="pfaOS['mean']">mm<sup>2</sup></span>
                    </ion-col>
                    <ion-col>
                        {{ pfaOS['median'] }} <span v-if="pfaOS['median']">mm<sup>2</sup></span>
                    </ion-col>
                    <ion-col>
                        {{ pfaOS['sd'] }} <span v-if="pfaOS['sd']">mm<sup>2</sup></span>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <strong>CA</strong>
                    </ion-col>
                    <ion-col>
                        {{ canthalTiltOS['mean'] }} <span v-if="canthalTiltOS['mean']">°</span>
                    </ion-col>
                    <ion-col>
                        {{ canthalTiltOS['median'] }} <span v-if="pfaOS['median']">°</span>
                    </ion-col>
                    <ion-col>
                        {{ canthalTiltOS['sd'] }} <span v-if="pfaOS['sd']">°</span>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <!-- OU -->
            <!-- <ion-row>
                <ion-text class='result-subtitle'>OD</ion-text>
            </ion-row> -->
            <ion-grid>
                <ion-row class="ion-align-items-center">
                    <ion-col>
                        <span class='side-title'>OU</span>
                    </ion-col>
                    <ion-col>
                        <strong>Mean</strong>
                    </ion-col>
                    <ion-col>
                        <strong>Median</strong>
                    </ion-col>
                    <ion-col>
                        <strong>SD</strong>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-align-items-center">
                    <ion-col>
                        <strong>ICD</strong>
                    </ion-col>
                    <ion-col>
                        {{ innerCanthal['mean'] }} <span v-if="innerCanthal['mean']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ innerCanthal['median'] }} <span v-if="innerCanthal['median']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ innerCanthal['sd'] }} <span v-if="innerCanthal['sd']">mm</span>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-align-items-center">
                    <ion-col>
                        <strong>OCD</strong>
                    </ion-col>
                    <ion-col>
                        {{ outerCanthal['mean'] }} <span v-if="outerCanthal['mean']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ outerCanthal['median'] }} <span v-if="outerCanthal['median']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ outerCanthal['sd'] }} <span v-if="outerCanthal['sd']">mm</span>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-align-items-center">
                    <ion-col>
                        <strong>IPD</strong>
                    </ion-col>
                    <ion-col>
                        {{ ipd['mean'] }} <span v-if="ipd['mean']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ ipd['median'] }} <span v-if="ipd['median']">mm</span>
                    </ion-col>
                    <ion-col>
                        {{ ipd['sd'] }} <span v-if="ipd['sd']">mm</span>
                    </ion-col>
                </ion-row>
                
            </ion-grid>

            <!-- Norm compare -->
            <ion-row class="ion-align-items-center">
                <ion-col>
                    <ion-button expand="block" @click="compareToNorm"
                    :disabled="!mrd1OD['mean'] || mrd1OD['mean'].length === 0">
                        <ion-icon src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxLjU7Ij4KICAgIDxnIGlkPSJBcnRib2FyZDEiIHRyYW5zZm9ybT0ibWF0cml4KDAuNDQ4Mzc3LDAsMCwwLjQ0ODM3NywtNy4zOTIxMywtNi44OTEyMSkiPgogICAgICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDAuNzAzMTI1LDAsMCwwLjcwMzEyNSwzNy45OTk4LDE3LjQ4MTcpIj4KICAgICAgICAgICAgPHBhdGggZD0iTTAsMjA4Ljg5NkM2Mi45MDgsMjAwLjk1OSA3NS4zOTYsNDYuMzg4IDEyOCw0Ni4zODhDMTgwLjYwNCw0Ni4zODggMTkzLjA5MiwyMDAuOTU5IDI1NiwyMDguODk2IiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTp3aGl0ZTtzdHJva2Utd2lkdGg6MzEuNzJweDsiLz4KICAgICAgICA8L2c+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMC44MTIzNDksMCwwLDEsMjQuODI0NSwtMy41NjMxMSkiPgogICAgICAgICAgICA8cGF0aCBkPSJNNC45NzMsMjA3LjIzTDI0OS4wNDQsMjA3LjIzIiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTp3aGl0ZTtzdHJva2Utd2lkdGg6MjQuMzZweDsiLz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo="
                        slot="start"></ion-icon><ion-label slot="end">Compare to Norm</ion-label>
                    </ion-button>
                </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
                <ion-col>
                    <ion-button expand="block" @click="saveResults()"
                    :disabled="!mrd1OD['mean'] || mrd1OD['mean'].length === 0">
                    <ion-icon :icon="downloadOutline" slot="start"></ion-icon><ion-label slot="end">Download Results</ion-label>
                </ion-button>
                </ion-col>
            </ion-row>


            
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-header>
            <ion-card-title class='card-title'>Legend</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <ion-grid>
                <ion-row>
                    <ion-col size='3'>
                        <strong>MRD1</strong>
                    </ion-col>
                    <ion-col size='9'>
                        Margin reflex distance 1
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size='3'>
                        <strong>MRD2</strong>
                    </ion-col>
                    <ion-col size='9'>
                        Margin reflex distance 2
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size='3'>
                        <strong>PF H</strong>
                    </ion-col>
                    <ion-col size='9'>
                        Palpebral Fissure Height
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size='3'>
                        <strong>PF W</strong>
                    </ion-col>
                    <ion-col size='9'>
                        Palpebral Fissure Width
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size='3'>
                        <strong>PF A</strong>
                    </ion-col>
                    <ion-col size='9'>
                        Palpebral Fissure Area
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size='3'>
                        <strong>ICD</strong>
                    </ion-col>
                    <ion-col size='9'>
                        Inner Canthal Distance
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size='3'>
                        <strong>OCD</strong>
                    </ion-col>
                    <ion-col size='9'>
                        Outer Canthal Distance
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size='3'>
                        <strong>IPD</strong>
                    </ion-col>
                    <ion-col size='9'>
                        Interpupillary distance
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size='3'>
                        <strong>CA</strong>
                    </ion-col>
                    <ion-col size='9'>
                        Canthal Angle
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <strong>Note:</strong> Measurements are sensitive to head position. Ensure the patient is looking straight forward with no right/left or up/down head tilt.
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-card-content>
      </ion-card>
      

      <ion-text class="copyright" color="dark"
        >© 2024 Jeremy Moreau, MD, PhD<br>Section of Ophthalmology, University of Calgary
        <br>
        Use of this app is subject to <router-link to="/disclaimer">Disclaimer</router-link>
        <br>
        <span class="version">version {{ appVersion }}</span>
      </ion-text>
    </ion-content>
  </ion-page>
</template>

<script lang='js'>
import { defineComponent } from "vue";
import HeaderBar from "./HeaderBar.vue";
import packageJson from '../../package.json';
import {IonPage, IonContent, IonSpinner, loadingController, IonRadioGroup, IonRadio, IonInput,
    // IonToggle,
    IonCardHeader,
    IonCardTitle,
    IonCard,
    IonCardContent,
    IonGrid,
    IonList,
    IonItem,
    IonText,
    IonRow,
    IonCol,
    IonButton,
    IonLabel,
    IonIcon} from '@ionic/vue';
import { cameraOutline, folderOpenOutline, timerOutline, downloadOutline } from 'ionicons/icons';
import { FaceMesh, FACEMESH_TESSELATION, FACEMESH_RIGHT_EYE, FACEMESH_RIGHT_EYEBROW, FACEMESH_RIGHT_IRIS, FACEMESH_LEFT_EYE , FACEMESH_LEFT_EYEBROW, FACEMESH_LEFT_IRIS, FACEMESH_FACE_OVAL, FACEMESH_LIPS } from '@mediapipe/face_mesh';
import { drawingUtils, drawConnectors } from '@mediapipe/drawing_utils';
import { FaceDetection } from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
// const faceLandmarksDetection = require('@tensorflow-models/face-landmarks-detection');
import {
  mean, median, std, min, max, round, distance, square, sqrt, intersect, cos, sin, atan2, pi
} from 'mathjs';
import cv from "@techstark/opencv-js"
import iro from '@jaames/iro'
const area = require('area-polygon')


// Global object to store data
window.ophthoruler = {}

// Global utility functions
function rotatePoint(x, y, theta) {
    const radVal = theta * (pi / 180);
    const cosVal = cos(radVal);
    const sinVal = sin(radVal);
    const xNew = x * cosVal - y * sinVal;
    const yNew = x * sinVal + y * cosVal;
    return [xNew, yNew];
}
function calculateCanthalTilt(medialX, medialY, lateralX, lateralY, headTilt, isOS) {
    // Calculate the differences in coordinates
    const deltaY = lateralY - medialY;
    const deltaX = lateralX - medialX;

    // Calculate the length of the line connecting the medial and lateral canthus
    const length = Math.sqrt(deltaX ** 2 + deltaY ** 2);

    // Adjust the angle of the line to account for head tilt
    const adjustedAngle = Math.atan2(deltaY, deltaX) - (headTilt * Math.PI) / 180;

    // Calculate the adjusted coordinates of the lateral canthus
    const adjLateralX = medialX + length * Math.cos(adjustedAngle);
    const adjLateralY = medialY + length * Math.sin(adjustedAngle);

    // Calculate the canthal tilt angle in degrees
    let canthalTiltRadians = Math.atan2(adjLateralY - medialY, adjLateralX - medialX);
    let canthalTiltDegrees = canthalTiltRadians * (180 / Math.PI);

    // Adjust the angle to get negative values for upward tilts
    // if (canthalTiltDegrees > 90) {
    //     canthalTiltDegrees -= 180;
    // }

    // If it's the left eye, adjust the angle calculation
    let canthalTiltDegreesAdjusted = 0;
    if (isOS) {
        if (canthalTiltDegrees > 90) {
            canthalTiltDegreesAdjusted = -(canthalTiltDegrees - 180);
        } else {
            canthalTiltDegreesAdjusted = -(180 + canthalTiltDegrees);
        }
    } else {
        canthalTiltDegreesAdjusted = canthalTiltDegrees;
    }

    return canthalTiltDegreesAdjusted;
}
function drawRect(canvasID, x, y, width, height, colour) {
            let canvas = document.getElementById(canvasID)
            let ctx = canvas.getContext("2d")
            ctx.beginPath()
            ctx.rect(x, y, width, height)
            ctx.strokeStyle = colour
            ctx.fillStyle = colour
            ctx.lineWidth = 1
            ctx.fill()
            // ctx.stroke()
        }
function drawCircle(canvasID, x, y, radius, colour, fill) {
            // canvasID: string
            // x: number
            // y: number
            // radius: number
            // fill: bool
            let canvas = document.getElementById(canvasID)
            let ctx = canvas.getContext("2d")
            ctx.beginPath()
            ctx.arc(x, y, radius, 0, 2 * Math.PI)
            ctx.strokeStyle = colour
            ctx.lineWidth = 2
            ctx.stroke()
            if (fill === true) {
                ctx.fillStyle = colour;
                ctx.fill();
            }
        }


export default defineComponent({
  name: "SelectMorphometryVideo",
  components: {
    HeaderBar,
    IonPage,
    IonContent,
    // IonToggle, 
    IonRadioGroup, 
    IonRadio,
    IonInput,
    IonCardHeader,
    IonCardTitle,
    IonCard,
    IonCardContent,
    IonGrid,
    IonList,
    IonItem,
    IonText,
    IonRow,
    IonCol,
    IonButton,
    IonLabel,
    IonIcon
  },
  
  data() {
      return {
          appVersion: packageJson.version,
          imageDataOD: "",
          imageDataOS: "",
          computeBtnIsDisabled: true,
          sampleCount: 0,
          sampleMax: 300,
          recordData: [],

          ipd: {},
          innerCanthal: {},
          outerCanthal: {},

          mrd1OD: {},
          mrd2OD: {},
          pfhOD: {},
          pfwOD: {},
          pfaOD: {},
          canthalTiltOD: {},

          mrd1OS: {},
          mrd2OS: {},
          pfhOS: {},
          pfwOS: {},
          pfaOS: {},
          canthalTiltOS: {},

          camera: {},

          colorPicker: {},

          highAccuracyMode: false,
          highAccModeSelected: 'small',
          highAccW: 47.6,
          highAccH: 34.9 
      }
      
  },

  setup() {
    // Load icons
      return {
          cameraOutline, folderOpenOutline, timerOutline, downloadOutline
      }
  },

  mounted() {
      if (this.$route.name === "SelectMorphometryVideo") {
          this.runVideoMorphometry()
      }

    this.colorPicker = new iro.ColorPicker("#picker", {
        // Set the size of the color picker
        width: 200,
        // Set the initial color to pure red
        color: "#ff2ef5"
         });
  },
  beforeRouteLeave (to, from , next) {
    // stop camera before moving to another page
    if (this.camera.camera) {
        this.camera.camera.stop()
        delete this.camera.camera
    }
    next()
    },
  methods: {
      compareToNorm() {
        try {
            const OD = JSON.stringify({
            'MRD1': this.mrd1OD.mean,
            'MRD2': this.mrd2OD.mean,
            'PFH': this.pfhOD.mean,
            'PFW': this.pfwOD.mean,
            });
            const OS = JSON.stringify({
            'MRD1': this.mrd1OS.mean,
            'MRD2': this.mrd2OS.mean, 
            'PFH': this.pfhOS.mean,
            'PFW': this.pfwOS.mean,
            });
            const OU = JSON.stringify({
            'ICD': this.innerCanthal.mean,
            'OCD': this.outerCanthal.mean,
            'IPD': this.ipd.mean
            });

            // console.log('Navigating to CompareToNorm');
            // console.log(this.$router)
            this.$router.push({ name: 'CompareToNorm', query: { 'OD': OD, 'OS': OS, 'OU': OU } })
        } catch (error) {
            console.error('Error comparing to norm:', error);
        }
        },

      async setHighAccValues(inputSize) {
          if (inputSize === 'regular') {
              this.highAccW = 76
              this.highAccH = 76
          } else {
              this.highAccW = 47.6
              this.highAccH = 34.9
          }
      },
      async runVideoMorphometry() {
            const videoElement = document.getElementById('input_video')
            const canvasElement = document.getElementById('output_canvas')
            const canvasCtx = canvasElement.getContext('2d')
            const cH = 300
            const cW = 300
            
            // Display loading message
            canvasCtx.font = "20px Arial"
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                // The user has requested the system use a dark colour theme
                canvasCtx.fillStyle = 'white';
            } else {
                // The user has requested the system use a light colour theme
                canvasCtx.fillStyle = 'black';
            }
            if (this.$route.query.cameraMode === 'user') {
                canvasCtx.save();
                canvasCtx.scale(-1, 1); // Flip the context horizontally
                canvasCtx.fillText("Loading...", -canvasCtx.canvas.width + 30, 50);
                canvasCtx.restore();
            } else {
                canvasCtx.fillText("Loading...", 30, 50);
            }

            let onResults = function(results) {
            canvasCtx.save();
            canvasCtx.canvas.width = window.innerWidth;
            canvasCtx.canvas.height = window.innerWidth;
            canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
            canvasCtx.drawImage(
                results.image, 0, 0, canvasElement.width, canvasElement.height);
            if (results.multiFaceLandmarks) {


                // high-accuracy mode
                let scaleFactorN = 0
                let scaleFactorN2 = 0

                // display colour picker
                // if (this.highAccuracyMode) {
                //     document.getElementById( 'picker' ).style.display = 'block';
                // } else {
                //     document.getElementById( 'picker' ).style.display = 'none';
                // }

                if (this.highAccuracyMode) {
                    // get colour picker val
                    let colourH = this.colorPicker.color.hsv.h*180/360
                    let colourS = this.colorPicker.color.hsv.s*255/100
                    let colourV = this.colorPicker.color.hsv.v*255/100
                    // console.log(this.colorPicker.color.hexString, colourH, colourS, colourV)

                    // console.log(colour.h+50)
                    // console.log(this.highAccuracyMode, this.highAccW, this.highAccH)

                    // get input canvas image
                    let src = new cv.Mat(canvasCtx.canvas.height, canvasCtx.canvas.width, cv.CV_8UC4)
                    src.data.set(canvasCtx.getImageData(0, 0, canvasCtx.canvas.width, canvasCtx.canvas.height).data)

                    // to HSV
                    let frameHSV = new cv.Mat();
                    cv.cvtColor(src, frameHSV, cv.COLOR_RGB2HSV, 0)

                    // threshhold (pink only for now)
                    let frameMask = new cv.Mat()
                    let low = new cv.Mat(frameHSV.rows, frameHSV.cols, frameHSV.type(),
                                        [colourH-50, colourS-100, colourV-100, 0]);
                    let high = new cv.Mat(frameHSV.rows, frameHSV.cols, frameHSV.type(),
                                        [180, 255, 255, 255])
                    // let low = new cv.Mat(frameHSV.rows, frameHSV.cols, frameHSV.type(),
                    //                     [100, 75, 150, 0]);
                    // let high = new cv.Mat(frameHSV.rows, frameHSV.cols, frameHSV.type(),
                    //                     [180, 255, 255, 255])
                    cv.inRange(frameHSV, low, high, frameMask);

                    // Find the largest contour
                    let contours = new cv.MatVector()
                    let hierarchy = new cv.Mat()
                    cv.findContours(frameMask, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE)
                    
                    let sortableContours = [];
                    for (let i = 0; i < contours.size(); i++) {
                        let cnt = contours.get(i);
                        let area = cv.contourArea(cnt, false);
                        let perim = cv.arcLength(cnt, false);

                        sortableContours.push({ areaSize: area, perimiterSize: perim, contour: cnt });
                    }
                    sortableContours = sortableContours.sort((item1, item2) => { return (item1.areaSize > item2.areaSize) ? -1 : (item1.areaSize < item2.areaSize) ? 1 : 0; }).slice(0, 1);
                    
                    // If a large contour is found, find the 4 corners (note: corners are not ordered)
                    if (sortableContours.length > 0) {
                      //Ensure the top area contour has 4 corners
                        let approx = new cv.Mat();
                        cv.approxPolyDP(sortableContours[0].contour, approx, .05 * sortableContours[0].perimiterSize, true);

                        if (approx.rows == 4) {
                            // console.log('Contour found!');

                            let corner1 = [approx.data32S[0], approx.data32S[1]]
                            let corner2 = [approx.data32S[2], approx.data32S[3]]
                            let corner3 = [approx.data32S[4], approx.data32S[5]]
                            let corner4 = [approx.data32S[6], approx.data32S[7]]

                            // calculate Width, Height, and Diag between each points
                            let corner1Distances = [distance(corner1, corner2),
                                                distance(corner1, corner3),
                                                distance(corner1, corner4)]
                            corner1Distances.sort()
                            let corner2Distances = [distance(corner2, corner1),
                                                distance(corner2, corner3),
                                                distance(corner2, corner4)]
                            corner2Distances.sort()
                            let corner3Distances = [distance(corner3, corner1),
                                                distance(corner3, corner2),
                                                distance(corner3, corner4)]
                            corner3Distances.sort()
                            let corner4Distances = [distance(corner4, corner1),
                                                distance(corner4, corner2),
                                                distance(corner4, corner3)]
                            corner4Distances.sort()
                            // Get Mean Width, Height, Diag. Height is shortest, Diag is longest
                            let rectWidth = mean(corner1Distances[1],corner2Distances[1],corner3Distances[1],corner4Distances[1])
                            let rectHeight = mean(corner1Distances[0],corner2Distances[0],corner3Distances[0],corner4Distances[0])
                            let rectDiag = mean(corner1Distances[2],corner2Distances[2],corner3Distances[2],corner4Distances[2])
                            // console.log(rectWidth, rectHeight, rectDiag)

                            // Calculate scale factor
                            let rectRealW = this.highAccW
                            let rectRealH = this.highAccH
                            let rectRealD = sqrt(square(rectRealW)+square(rectRealH))
                            let canvasD = sqrt(square(canvasCtx.canvas.width)+square(canvasCtx.canvas.height))

                            scaleFactorN = mean([(rectWidth / rectRealW / canvasCtx.canvas.width),
                                                     (rectHeight / rectRealH / canvasCtx.canvas.height),
                                                     (rectDiag / rectRealD / canvasD)])
                            scaleFactorN2 = mean([(square(rectWidth) / square(rectRealW) / square(canvasCtx.canvas.width)),
                                                      (square(rectHeight) / square(rectRealH) / square(canvasCtx.canvas.height)),
                                                      (square(rectDiag) / square(rectRealD) / square(canvasD))])
                            // console.log(scaleFactorN)

                            // display 4 corners of rect
                            canvasCtx.fillStyle = "#00FF00";
                            canvasCtx.fillRect(corner1[0]-2, corner1[1]-2, 4,4)
                            // canvasCtx.fillStyle = "#0000FF";
                            canvasCtx.fillRect(corner2[0]-2, corner2[1]-2, 4,4)
                            // canvasCtx.fillStyle = "#FF0000";
                            canvasCtx.fillRect(corner3[0]-2, corner3[1]-2, 4,4)
                            canvasCtx.fillRect(corner4[0]-2, corner4[1]-2, 4,4)
                        }
                        else{
                            // console.log('No 4-corner large contour!');
                        }
                        approx.delete()
                    }
 
                
                    // display
                    // let color = new cv.Scalar(0,255,255,255);
                    // for (let i = 0; i < contours.size(); ++i) {
                    //     cv.drawContours(src, contours, i, color, 1, cv.LINE_8, hierarchy, 100);
                    // }
                    // cv.imshow("output_canvas2", src)

                    src.delete()
                    frameHSV.delete()
                    frameMask.delete()
                    low.delete()
                    high.delete()
                    contours.delete()
                    hierarchy.delete()
                    
            
                }
                

                // calculate result values
                let face0 = results.multiFaceLandmarks[0]

                if (face0) {  // calculate result values only if a face is present in frame
                    // define eye bounding boxes
                    const boxOD = {
                        height: distance([face0[119]['x'],face0[119]['y']], [face0[52]['x'],face0[52]['y']]),
                        width: distance([face0[245]['x'],face0[245]['y']], [face0[35]['x'],face0[35]['y']]),
                        xMax: face0[245]['x'],
                        xMin: face0[35]['x'],
                        yMax: face0[119]['y'],
                        yMin: face0[52]['y'],
                    }
                    const boxOS = {
                        height: distance([face0[348]['x'],face0[348]['y']], [face0[282]['x'],face0[282]['y']]),
                        width: distance([face0[446]['x'],face0[446]['y']], [face0[465]['x'],face0[465]['y']]),
                        xMax: face0[446]['x'],
                        xMin: face0[465]['x'],
                        yMax: face0[348]['y'],
                        yMin: face0[282]['y'],
                    }
                    // define cornea
                    const corneaOD = {
                        x: face0[468]['x'],
                        y: face0[468]['y'],
                        h1: [face0[469]['x'],face0[469]['y']],
                        h2: [face0[471]['x'],face0[471]['y']],
                        radius: ( distance([face0[469]['x'],face0[469]['y']],
                                           [face0[471]['x'],face0[471]['y']])  )/2
                    }
                    const corneaOS = {
                        x: face0[473]['x'],
                        y: face0[473]['y'],
                        h1: [face0[474]['x'],face0[474]['y']],
                        h2: [face0[476]['x'],face0[476]['y']],
                        radius: ( distance([face0[474]['x'],face0[474]['y']],
                                           [face0[476]['x'],face0[476]['y']])  )/2
                    }
                    // define palpebral fissure
                    const palpebralOD = {
                        // points: [
                        //     {x:, y:}
                        // ],
                        medialCanthus: {x:face0[133]['x'], y:face0[133]['y']},
                        lateralCanthus: {x:face0[33]['x'], y:face0[33]['y']},
                        infPoint: {x:face0[145]['x'], y:face0[145]['y']},
                        supPoint: {x:face0[159]['x'], y:face0[159]['y']},
                        outline: [ 
                            [face0[33]['x'],face0[33]['y']],
                            [face0[246]['x'],face0[246]['y']],
                            [face0[161]['x'],face0[161]['y']],
                            [face0[160]['x'],face0[160]['y']],
                            [face0[159]['x'],face0[159]['y']],
                            [face0[158]['x'],face0[158]['y']],
                            [face0[157]['x'],face0[157]['y']],
                            [face0[173]['x'],face0[173]['y']],
                            [face0[133]['x'],face0[133]['y']],
                            [face0[155]['x'],face0[155]['y']],
                            [face0[154]['x'],face0[154]['y']],
                            [face0[153]['x'],face0[153]['y']],
                            [face0[145]['x'],face0[145]['y']],
                            [face0[144]['x'],face0[144]['y']],
                            [face0[163]['x'],face0[163]['y']],
                            [face0[7]['x'],face0[7]['y']]
                        ]
                    }
                    const palpebralOS = {
                        // points: [
                        //     {x:, y:}
                        // ],
                        medialCanthus: {x:face0[362]['x'], y:face0[362]['y']},
                        lateralCanthus: {x:face0[263]['x'], y:face0[263]['y']},
                        infPoint: {x:face0[374]['x'], y:face0[374]['y']},
                        supPoint: {x:face0[386]['x'], y:face0[386]['y']},
                        outline: [ 
                            [face0[263]['x'],face0[263]['y']],
                            [face0[466]['x'],face0[466]['y']],
                            [face0[388]['x'],face0[388]['y']],
                            [face0[387]['x'],face0[387]['y']],
                            [face0[386]['x'],face0[386]['y']],
                            [face0[385]['x'],face0[385]['y']],
                            [face0[384]['x'],face0[384]['y']],
                            [face0[398]['x'],face0[398]['y']],
                            [face0[362]['x'],face0[362]['y']],
                            [face0[382]['x'],face0[382]['y']],
                            [face0[381]['x'],face0[381]['y']],
                            [face0[380]['x'],face0[380]['y']],
                            [face0[374]['x'],face0[374]['y']],
                            [face0[373]['x'],face0[373]['y']],
                            [face0[390]['x'],face0[390]['y']],
                            [face0[249]['x'],face0[249]['y']]
                        ]
                    }

                    // scaleFactor: au to mm, scaleFactor2 au^2 to mm^2. Average OD and OS cornea for 2 samples
                    const corneaRadius = 5.855 // white to white = 11.71mm doi: 10.1097/01.ico.0000148312.01805.53
                    let scaleFactor = ((corneaOD.radius / corneaRadius) + (corneaOS.radius / corneaRadius))/2
                    let scaleFactor2 = ((square(corneaOD.radius) / square(corneaRadius)) + (square(corneaOS.radius) / square(corneaRadius)))/2
                    // console.log('cornea', scaleFactor)
                    // Use note dimensions instead of corneal radius if high-accuracy mode available
                    if (this.highAccuracyMode) {
                        if (scaleFactorN !== 0) {
                            scaleFactor = scaleFactorN
                            scaleFactor2 = scaleFactorN2
                        }
                    }
                    // console.log(scaleFactor)

                    // Calculated measures
                    const headTtilt_deltay = palpebralOD.lateralCanthus.y - palpebralOS.lateralCanthus.y
                    const headTtilt_deltax = palpebralOD.lateralCanthus.x - palpebralOS.lateralCanthus.x
                    const headTtilt_theta = atan2(headTtilt_deltay, headTtilt_deltax)*(180/pi)
                    // console.log(headTtilt_theta)
                    // Use distance from intersect between mid corneal horizontal line and sup-inf PF line and sup or inf PF point for MRD1/MRD2
                    const MidCornealIntersectOD = intersect(corneaOD.h1, corneaOD.h2, Object.values(palpebralOD.supPoint), Object.values(palpebralOD.infPoint))
                    const mrd1OD = distance(MidCornealIntersectOD,
                                            [palpebralOD.supPoint.x, palpebralOD.supPoint.y])
                    const mrd2OD = distance(MidCornealIntersectOD,
                                            [palpebralOD.infPoint.x,palpebralOD.infPoint.y])
                    const pfhOD = distance([palpebralOD.infPoint.x,palpebralOD.infPoint.y],
                                           [palpebralOD.supPoint.x,palpebralOD.supPoint.y])
                    const pfwOD = distance([palpebralOD.medialCanthus.x,palpebralOD.medialCanthus.y],
                                           [palpebralOD.lateralCanthus.x,palpebralOD.lateralCanthus.y])
                    const pfaOD = area(palpebralOD.outline)
                    const canthalTiltOD = calculateCanthalTilt(palpebralOD.medialCanthus.x, palpebralOD.medialCanthus.y, palpebralOD.lateralCanthus.x, palpebralOD.lateralCanthus.y, headTtilt_theta, false);

                    const MidCornealIntersectOS = intersect(corneaOS.h1, corneaOS.h2, Object.values(palpebralOS.supPoint), Object.values(palpebralOS.infPoint))
                    const mrd1OS = distance(MidCornealIntersectOS,
                                            [palpebralOS.supPoint.x, palpebralOS.supPoint.y])
                    const mrd2OS = distance(MidCornealIntersectOS,
                                            [palpebralOS.infPoint.x,palpebralOS.infPoint.y])
                    const pfhOS = distance([palpebralOS.infPoint.x,palpebralOS.infPoint.y],
                                           [palpebralOS.supPoint.x,palpebralOS.supPoint.y])
                    const pfwOS = distance([palpebralOS.medialCanthus.x,palpebralOS.medialCanthus.y],
                                           [palpebralOS.lateralCanthus.x,palpebralOS.lateralCanthus.y])
                    const pfaOS = area(palpebralOS.outline)
                    const canthalTiltOS = calculateCanthalTilt(palpebralOS.medialCanthus.x, palpebralOS.medialCanthus.y, palpebralOS.lateralCanthus.x, palpebralOS.lateralCanthus.y, headTtilt_theta, true);

                    const ipd = distance(MidCornealIntersectOD, MidCornealIntersectOS)
                    const innerCanthal = distance([palpebralOS.medialCanthus.x, palpebralOS.medialCanthus.y],
                                                  [palpebralOD.medialCanthus.x, palpebralOD.medialCanthus.y])
                    const outerCanthal = distance([palpebralOS.lateralCanthus.x, palpebralOS.lateralCanthus.y],
                                                  [palpebralOD.lateralCanthus.x, palpebralOD.lateralCanthus.y])

                    const morphometryResults = {
                        // canvas: canvasCtx,
                        scaleFactor: scaleFactor,
                        // calculated values
                        mrd1OD: mrd1OD/scaleFactor,
                        mrd2OD: mrd2OD/scaleFactor,
                        pfhOD: pfhOD/scaleFactor,
                        pfwOD: pfwOD/scaleFactor,
                        pfaOD: pfaOD/scaleFactor2,
                        canthalTiltOD: canthalTiltOD,

                        mrd1OS: mrd1OS/scaleFactor,
                        mrd2OS: mrd2OS/scaleFactor,
                        pfhOS: pfhOS/scaleFactor,
                        pfwOS: pfwOS/scaleFactor,
                        pfaOS: pfaOS/scaleFactor2,
                        canthalTiltOS: canthalTiltOS,

                        ipd: ipd/scaleFactor,
                        innerCanthal: innerCanthal/scaleFactor,
                        outerCanthal: outerCanthal/scaleFactor,
                        // points
                        faceMesh: face0,
                        boxOD: boxOD,
                        boxOS: boxOS,
                        corneaOD: corneaOD,
                        corneaOS: corneaOS,
                        palpebralOD: palpebralOD,
                        palpebralOS: palpebralOS
                    }

                    // draw corneal intersect point
                    canvasCtx.fillStyle = "#F802E1"
                    canvasCtx.fillRect(MidCornealIntersectOD[0]*canvasElement.width-2, MidCornealIntersectOD[1]*canvasElement.height-2, 4,4)
                    canvasCtx.fillRect(MidCornealIntersectOS[0]*canvasElement.width-2, MidCornealIntersectOS[1]*canvasElement.height-2, 4,4)
                    // canvasCtx.arc(MidCornealIntersectOD[0]*canvasElement.width, MidCornealIntersectOD[1]*canvasElement.height, 2, 0, 4 * Math.PI, false)
                    // canvasCtx.fill()
                    // canvasCtx.arc(MidCornealIntersectOS[0]*canvasElement.width, MidCornealIntersectOS[1]*canvasElement.height, 2, 0, 4 * Math.PI, false)
                    // canvasCtx.fill()

                    // draw supPoint
                    canvasCtx.fillStyle = "#07fd1e"
                    canvasCtx.fillRect(palpebralOD.supPoint.x*canvasElement.width-2, palpebralOD.supPoint.y*canvasElement.height-2, 4,4)
                    canvasCtx.fillRect(palpebralOS.supPoint.x*canvasElement.width-2, palpebralOS.supPoint.y*canvasElement.height-2, 4,4)

                    // log results
                    if (this.sampleCount > 0 ) {
                        this.recordData.push(morphometryResults)
                        // console.log(this.sampleCount)
                        this.sampleCount += 1

                    }
                    // Calculate and display final results when all samples collected
                    if (this.sampleCount > this.sampleMax) {

                        // log values in array
                        let mrd1ODArray = []
                        let mrd2ODArray = []
                        let pfhODArray = []
                        let pfwODArray = []
                        let pfaODArray = []
                        let canthalTiltODArray = []
                        // console.log(canthalTiltODArray)

                        let mrd1OSArray = []
                        let mrd2OSArray = []
                        let pfhOSArray = []
                        let pfwOSArray = []
                        let pfaOSArray = []
                        let canthalTiltOSArray = []

                        let ipdArray = []
                        let innerCanthalArray = []
                        let outerCanthalArray = []
                        for (const sample of this.recordData) {
                            mrd1ODArray.push(sample['mrd1OD'])
                            mrd2ODArray.push(sample['mrd2OD'])
                            pfhODArray.push(sample['pfhOD'])
                            pfwODArray.push(sample['pfwOD'])
                            pfaODArray.push(sample['pfaOD'])
                            canthalTiltODArray.push(sample['canthalTiltOD'])

                            mrd1OSArray.push(sample['mrd1OS'])
                            mrd2OSArray.push(sample['mrd2OS'])
                            pfhOSArray.push(sample['pfhOS'])
                            pfwOSArray.push(sample['pfwOS'])
                            pfaOSArray.push(sample['pfaOS'])
                            canthalTiltOSArray.push(sample['canthalTiltOS'])

                            ipdArray.push(sample['ipd'])
                            innerCanthalArray.push(sample['innerCanthal'])
                            outerCanthalArray.push(sample['outerCanthal'])
                        }

                        // calculate mean/SD
                        // console.log(pfwODArray)
                        //OD
                        // console.log(this.pfwODArray)
                        this.mrd1OD.mean = round(mean(mrd1ODArray),1)
                        this.mrd1OD.median = round(median(mrd1ODArray),1)
                        this.mrd1OD.sd = round(std(mrd1ODArray),1)
                        this.mrd2OD.mean = round(mean(mrd2ODArray),1)
                        this.mrd2OD.median = round(median(mrd2ODArray),1)
                        this.mrd2OD.sd = round(std(mrd2ODArray),1)
                        this.pfhOD.mean = round(mean(pfhODArray),1)
                        this.pfhOD.median = round(median(pfhODArray),1)
                        this.pfhOD.sd = round(std(pfhODArray),1)
                        this.pfwOD.mean = round(mean(pfwODArray),1)
                        this.pfwOD.median = round(median(pfwODArray),1)
                        this.pfwOD.sd = round(std(pfwODArray),1)
                        this.pfaOD.mean = round(mean(pfaODArray),0)
                        this.pfaOD.median = round(median(pfaODArray),0)
                        this.pfaOD.sd = round(std(pfaODArray),0)
                        this.canthalTiltOD.mean = round(mean(canthalTiltODArray),0)
                        this.canthalTiltOD.median = round(median(canthalTiltODArray),0)
                        this.canthalTiltOD.sd = round(std(canthalTiltODArray),1)

                        this.mrd1OS.mean = round(mean(mrd1OSArray),1)
                        this.mrd1OS.median = round(median(mrd1OSArray),1)
                        this.mrd1OS.sd = round(std(mrd1OSArray),1)
                        this.mrd2OS.mean = round(mean(mrd2OSArray),1)
                        this.mrd2OS.median = round(median(mrd2OSArray),1)
                        this.mrd2OS.sd = round(std(mrd2OSArray),1)
                        this.pfhOS.mean = round(mean(pfhOSArray),1)
                        this.pfhOS.median = round(median(pfhOSArray),1)
                        this.pfhOS.sd = round(std(pfhOSArray),1)
                        this.pfwOS.mean = round(mean(pfwOSArray),1)
                        this.pfwOS.median = round(median(pfwOSArray),1)
                        this.pfwOS.sd = round(std(pfwOSArray),1)
                        this.pfaOS.mean = round(mean(pfaOSArray),0)
                        this.pfaOS.median = round(median(pfaOSArray),0)
                        this.pfaOS.sd = round(std(pfaOSArray),0)
                        this.canthalTiltOS.mean = round(mean(canthalTiltOSArray),0)
                        this.canthalTiltOS.median = round(median(canthalTiltOSArray),0)
                        this.canthalTiltOS.sd = round(std(canthalTiltOSArray),1)

                        this.ipd.mean = round(mean(ipdArray),0)
                        this.ipd.median = round(median(ipdArray),0)
                        this.ipd.sd = round(std(ipdArray),0)
                        this.innerCanthal.mean = round(mean(innerCanthalArray),0)
                        this.innerCanthal.median = round(median(innerCanthalArray),0)
                        this.innerCanthal.sd = round(std(innerCanthalArray),1)
                        this.outerCanthal.mean = round(mean(outerCanthalArray),0)
                        this.outerCanthal.median = round(median(outerCanthalArray),0)
                        this.outerCanthal.sd = round(std(outerCanthalArray),1)
                        
                        // console.log(mean(mrd1ODArray))

                        // reset counter
                        window.test = this.recordData
                        this.recordData = []
                        this.sampleCount = 0
                        // console.log(this.recordData)
                    }
                }

                // console.log(FACEMESH_RIGHT_IRIS[3])
                for (const landmarks of results.multiFaceLandmarks) {
                // drawConnectors(canvasCtx, landmarks, FACEMESH_TESSELATION,
                                // {color: '#C0C0C070', lineWidth: 1});
                drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYE, {color: '#FF0D00', lineWidth:2});
                drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYEBROW, {color: '#FF0D00', lineWidth:2});
                drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_IRIS, {color: '#FF0D00', lineWidth:2});
                drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYE, {color: '#00F2FF', lineWidth:2});
                drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYEBROW, {color: '#00F2FF', lineWidth:2});
                drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_IRIS, {color: '#00F2FF', lineWidth:2});
                drawConnectors(canvasCtx, landmarks, FACEMESH_FACE_OVAL, {color: '#E0E0E0', lineWidth:2});
                // drawConnectors(canvasCtx, landmarks, FACEMESH_LIPS, {color: '#E0E0E0'});
                }
            }
            canvasCtx.restore();
            }.bind(this)

            const faceMesh = new FaceMesh({locateFile: (file) => {
            return `/models/face_mesh/${file}`;
            }});
            faceMesh.setOptions({
            maxNumFaces: 1,
            refineLandmarks: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5
            });
            faceMesh.onResults(onResults);

            console.log('camera mode:', this.$route.query.cameraMode)

            // Create Camera
            // console.log('starting camera')
            this.camera.camera = new Camera(videoElement, {
                    onFrame: async () => {
                        await faceMesh.send({image: videoElement});
                    },
                    facingMode: this.$route.query.cameraMode,
                    width: 300,
                    height: 300
                });
            // Start Camera
            this.camera.camera.start();
    },
    async recordResults(nSamples) {
        this.sampleCount = 1
        this.sampleMax = nSamples
        
    },
    async saveResults() {
        const resultsArray = [
                        this.mrd1OD.mean,
                        this.mrd1OD.median,
                        this.mrd1OD.sd,
                        this.mrd2OD.mean,
                        this.mrd2OD.median,
                        this.mrd2OD.sd,
                        this.pfhOD.mean,
                        this.pfhOD.median,
                        this.pfhOD.sd,
                        this.pfwOD.mean,
                        this.pfwOD.median,
                        this.pfwOD.sd,
                        this.pfaOD.mean,
                        this.pfaOD.median,
                        this.pfaOD.sd,
                        this.canthalTiltOD.mean,
                        this.canthalTiltOD.median,
                        this.canthalTiltOD.sd,

                        this.mrd1OS.mean,
                        this.mrd1OS.median,
                        this.mrd1OS.sd,
                        this.mrd2OS.mean,
                        this.mrd2OS.median,
                        this.mrd2OS.sd,
                        this.pfhOS.mean,
                        this.pfhOS.median,
                        this.pfhOS.sd,
                        this.pfwOS.mean,
                        this.pfwOS.median,
                        this.pfwOS.sd,
                        this.pfaOS.mean,
                        this.pfaOS.median,
                        this.pfaOS.sd,
                        this.canthalTiltOS.mean,
                        this.canthalTiltOS.median,
                        this.canthalTiltOS.sd,

                        this.ipd.mean,
                        this.ipd.median,
                        this.ipd.sd,
                        this.innerCanthal.mean,
                        this.innerCanthal.median,
                        this.innerCanthal.sd,
                        this.outerCanthal.mean,
                        this.outerCanthal.median,
                        this.outerCanthal.sd,
        ]
        const resultsTitle = [
            "mrd1OD.mean",
            "mrd1OD.median",
            "mrd1OD.sd",
            "mrd2OD.mean",
            "mrd2OD.median",
            "mrd2OD.sd",
            "pfhOD.mean",
            "pfhOD.median",
            "pfhOD.sd",
            "pfwOD.mean",
            "pfwOD.median",
            "pfwOD.sd",
            "pfaOD.mean",
            "pfaOD.median",
            "pfaOD.sd",
            "canthalTiltOD.mean",
            "canthalTiltOD.median",
            "canthalTiltOD.sd",
            "mrd1OS.mean",
            "mrd1OS.median",
            "mrd1OS.sd",
            "mrd2OS.mean",
            "mrd2OS.median",
            "mrd2OS.sd",
            "pfhOS.mean",
            "pfhOS.median",
            "pfhOS.sd",
            "pfwOS.mean",
            "pfwOS.median",
            "pfwOS.sd",
            "pfaOS.mean",
            "pfaOS.median",
            "pfaOS.sd",
            "canthalTiltOS.mean",
            "canthalTiltOS.median",
            "canthalTiltOS.sd",
            "ipd.mean",
            "ipd.median",
            "ipd.sd",
            "innerCanthal.mean",
            "innerCanthal.median",
            "innerCanthal.sd",
            "outerCanthal.mean",
            "outerCanthal.median",
            "outerCanthal.sd",
        ]
        const resultsCSV = [resultsTitle.join(","), resultsArray.join(",")].join("\n")
        function download(filename, text) {
            var pom = document.createElement('a');
            pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            pom.setAttribute('download', filename);

            if (document.createEvent) {
                var event = document.createEvent('MouseEvents');
                event.initEvent('click', true, true);
                pom.dispatchEvent(event);
            }
            else {
                pom.click();
            }
        }
        const d = new Date()
        const dateTime = d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate() + "_" +
                         d.getHours() + "." + d.getMinutes() + "." + d.getSeconds()
        // const resultsFilename = "results_" + dateTime + ".csv"
        const resultsFilename = "results.csv"
        download(resultsFilename, resultsCSV)
        // const uriContent = "data:application/octet-stream" + encodeURIComponent(resultsCSV)
        // const newWindow = window.open(uriContent, 'results.csv');
    },
    async loadImg() {
        this.clearCanvas('imgFace_preview')
        // get image from hidden img
        const imgFace = window.cv.imread('imgFace', 0)

        // resize image
        let faceResized = new window.cv.Mat()
        const wNew = 500
        const hNew = wNew * imgFace.size().height / imgFace.size().width
        let dsize = new window.cv.Size(wNew, hNew);
        window.cv.resize(imgFace, faceResized, dsize, 0, 0, window.cv.INTER_AREA)

        // set image to visible canvas
        window.cv.imshow('imgFace_preview', faceResized)
    },
    clearCanvas(canvasID) {
      let canvas = document.getElementById(canvasID)
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    updateComputeBtn() {
        if (this.imageDataOD.length > 0) {
            this.computeBtnIsDisabled = false
        }
        console.log(this.computeBtnIsDisabled)
      },
    previewImage(eyeSide) {
      console.log('image changed')
        // Reference to the DOM input element
        var input = event.target;
        // Ensure that you have a file before attempting to read it
        if (input.files && input.files[0]) {
            // create a new FileReader to read this image and convert to base64 format
            var reader = new FileReader();
            // Define a callback function to run, when FileReader finishes its job
            reader.onload = (e) => {
                // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                // Read image as base64 and set to imageData
                if (eyeSide === 'OD') {
                    this.imageDataOD = e.target.result;
                    // check if compute Btn needs to be enabled
                    this.updateComputeBtn()
                } else {
                    this.imageDataOS = e.target.result;
                    // check if compute Btn needs to be enabled
                    this.updateComputeBtn()
                }
                
            }
            
            // Start the reader job - read file as a data url (base64 format)
            reader.readAsDataURL(input.files[0]);
        }
    },
  },
})
</script>

<style scoped>
/* ion-card-header {
    padding-top: 0px;
} */
.card-title {
  font-size: 1.5rem;
}
.image-preview {
    width: 100%;
}
.hidden-img {
  display: none;
}
.preview {
    width: 100%;
}
.custom-file-upload {
    /* border: 1px solid #ccc; */
    display: inline-block;
    /* padding: 20px; */
    cursor: pointer;
    width: 100%;
}
.hidden-input {
    visibility: hidden;
    position: absolute;
}
.disable-btn {
    pointer-events:none;
}
.disable-btn:hover {
    background-color: red !important;
}

.btn-label {
    margin-left: 10px;
}
#cardinal-logo {
  margin: auto;
  width: 180px;
}
#top-blurb {
  padding-top: 0px;
}
.main-content {
  padding: 10px;
}
.btn-group {
  padding: 10px;
}

.copyright {
  display: block;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  text-align: center;
  hyphens: none;
  padding-bottom: 20px;
}

.btn-spinner {
  height: 25px;
  margin-left: 10px;
}

/* Ensure the size of the image fit the container perfectly */
img {
  display: block;

  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}
#input_video {
    width: 100%;
    display: none;
}
#output_canvas{
    width: 100%;
}
#output_canvas2{
    width: 100%;
}
.result-heading {
  color: #1a1a1a;
  font-weight: 400;
}
.result-subtitle {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.15rem;
  font-weight: 700;
}
.side-title {
    font-weight: 800;
    font-size: 1.2rem;
    color: #000000;
}
#side-title-OD {
    color: #FF0D00;
}
#side-title-OS {
    color: #00F2FF;
}
.results-col {
    height: 100%;
    vertical-align: middle;
}
#high-accuracy-label {
    padding-left: 10px;
    vertical-align: middle;
}
#picker {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    display: none
}
.version {
  font-size: 0.9rem;
  font-family: monospace;
}
.flip-horizontal {
  transform: scaleX(-1);
}
</style>
<template>
  <ion-page>
    <HeaderBar></HeaderBar>

    <ion-content overflow-scroll="true">
      <ion-card>
        <ion-img
          id="logo"
          src="img/icons/ophthoruler_icon_nopad.svg"
        />
        <ion-card-content id="top-blurb">
          <ion-text>
            OphthoRuler is an app for quick ocular measurements. For best results, please ensure the face is facing straight-forward (not at an angle) and fills the frame (bring the camera closer). Fixate a distant point and avoid moving eyes or blinking while measuring.
          </ion-text>
        </ion-card-content>
      </ion-card>

      <ion-row class="ion-padding-horizontal">
      <ion-segment @ionChange="segmentChanged($event)" :value=cameraMode>
        <ion-segment-button value="user" layout="icon-start">
          <ion-icon :icon="personCircleOutline" class=""></ion-icon>
          <ion-label>Front Camera</ion-label>
        </ion-segment-button>
        <ion-segment-button value="environment" layout="icon-start">
          <ion-icon :icon="imageOutline" class=""></ion-icon>
          <ion-label>Rear Camera</ion-label>
        </ion-segment-button>
      </ion-segment>
      </ion-row>
        <ion-button class="ion-padding-horizontal ion-margin-vertical"
                    @click="gotoPage('SelectMorphometryVideo', cameraMode)"
                    expand="block"
                    > Morphometry
        </ion-button>

      
      <ion-text class="copyright" color="dark"
        >© 2024 Jeremy Moreau, MD, PhD<br>Section of Ophthalmology, University of Calgary
        <br>
        Use of this app is subject to <router-link to="/disclaimer">Disclaimer</router-link>
        <br>
        <span class="version">version {{ appVersion }}</span>
      </ion-text>
    </ion-content>
  </ion-page>
</template>

<script lang='js'>
import { defineComponent } from "vue";
import HeaderBar from "./HeaderBar.vue";
import packageJson from '../../package.json';
import {IonPage, IonContent, IonSpinner, IonSegment,
    IonImg,
    IonText,
    IonCardContent,
    IonCard,
    IonIcon,
    IonLabel,
    IonSegmentButton,
    IonRow,
    IonButton,} from '@ionic/vue';
import { personCircleOutline, imageOutline } from 'ionicons/icons';


export default defineComponent({
  name: "HomePage",
  components: {
    HeaderBar,
    IonPage,
    IonContent,
    IonSegment,
    IonImg,
    IonText,
    IonCardContent,
    IonCard,
    IonIcon,
    IonLabel,
    IonSegmentButton,
    IonRow,
    IonButton,
  },
  data() {
    return {
      appVersion: packageJson.version,
      cameraMode: 'user'
    }
  },
    setup() {
    return { 
      personCircleOutline, imageOutline
    }
  },
  mounted() {
  },
  methods: {
    gotoPage(pageName, cameraMode) {
      this.$router.push({
        name: pageName,
        query: { 'cameraMode': cameraMode },
      });
    },
    segmentChanged(CustomEvent) {
      this.cameraMode = CustomEvent.detail.value
      console.log(this.cameraMode)
    },

  },
})
</script>

<style scoped>
#logo {
  margin: auto;
  padding-top: 20px;
  width: 180px;
}
.main-content {
  padding: 10px;
}
.btn-group {
  padding: 10px;
}
.copyright {
  display: block;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  text-align: center;
  hyphens: none;
  padding-bottom: 20px;
}
.version {
  font-size: 0.9rem;
  font-family: monospace;
}
</style>
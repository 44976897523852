<template>
  <ion-page>
    <HeaderBar></HeaderBar>

    <ion-content overflow-scroll="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title> Install this App </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-text class="md-text"
            ><h3 id="iphone-ios">iPhone (iOS)</h3>
            <ol>
              <li>
                <p>
                  In Safari, tap the "share" icon at the bottom of the screen
                </p>
                <p>
                  <img
                    src="/assets/SideMenu-install/install-ios-step1.png"
                    alt="iOS installation instructions screenshot step 1"
                  />
                </p>
              </li>
              <li>
                <p>Select "Add to Home Screen"</p>
                <p>
                  <img
                    src="/assets/SideMenu-install/install-ios-step2.png"
                    alt="iOS installation instructions screenshot step 2"
                  />
                </p>
              </li>
            </ol>
            <h3 id="android">Android</h3>
            <ol>
              <li>
                <p>
                  In Google Chrome, tap the "3 dots" icon at the top right of
                  the screen
                </p>
                <p>
                  <img
                    src="/assets/SideMenu-install/install-android-step1.jpg"
                    alt="Android installation instructions screenshot step 1"
                  />
                </p>
              </li>
              <li>
                <p>Select "Install app"</p>
                <p>
                  <img
                    src="/assets/SideMenu-install/install-android-step2.jpg"
                    alt="Android installation instructions screenshot step 2"
                  />
                </p>
              </li>
            </ol>
          </ion-text>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang='js'>
import { defineComponent } from "vue";
import HeaderBar from "./HeaderBar.vue";
import {IonPage, IonContent, IonCardTitle, IonCardHeader, IonText, IonCard, IonCardContent} from '@ionic/vue';
export default defineComponent({
  name: "SideMenuInstall",
  components: {
    HeaderBar,
    IonPage,
    IonContent,
    IonCardTitle,
    IonCardHeader,
    IonText,
    IonCard,
    IonCardContent
  },
  data() {
    return {
      tippyTouchOptions: ['hold', 300],
      langvalue: 'en'
    }
  },
  mounted() {
    // disable context menu on nav icons (to not interfere with tooltips)
    [...document.querySelectorAll(".nav-icons")].forEach( el => 
    el.addEventListener('contextmenu', e => e.preventDefault())
    );
  },

  methods: {
    gotoPage(pageName) {
      this.$router.push({
        name: pageName,
      });
    },

  },
})
</script>

<style scoped>
.instructions-card {
  margin-top: 10px;
}
.header-title {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* Markdown text style */
 .md-text {
  font-size: 1rem;
}
.md-text p {
 font-size: 1rem;
 padding-top: 0rem;
 padding-bottom: 0.5rem;
 /* color:#333333; */
}
/* .dark .md-text p {
 color: #e2e2e2;
}
.md-text li {
 color: #333333;
} */
.dark .md-text li {
 color: #e2e2e2;
}
.md-text em {
 font-size: 0.9rem;
}
.md-text h2 {
 font-size: 1.1rem;
 font-weight: bold;
 hyphens: none;
}
.md-text h3 {
 font-size: 1.1rem;
 font-weight: bold;
 padding-top: 0.6rem;
 hyphens: none;
}
.md-text h3:first-child {
 padding-top: 0px;
}
.md-text h4 {
 font-size: 1rem;
 font-weight: 600;
 font-style: italic;
 padding-top: 0.6rem;
 padding-bottom: 0.4rem;
 hyphens: none;
}
.md-text ol {
 margin-top: 0px;
 padding-inline-start: 20px;
}
.md-text ul {
 margin-top: 0px;
 padding-inline-start: 20px;
}
.md-text ul li p {
   padding-top: 0px;
   padding-bottom: 0.2rem;
}
.md-text img {
   display: block;
   margin-left: auto;
   margin-right: auto;
   width: 95%;
   padding-top: 5px;
   padding-bottom: 5px;
}
.md-text a {
 text-decoration: none;
}
.md-text table {
 width: 100%
}
.md-text td {
 text-align: center;
}
</style>
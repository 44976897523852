<template>
    <canvas ref="canvas"></canvas>
</template>
<script>
import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale, Filler } from 'chart.js'

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Filler);

export default {
  props: ['ageRangeData'],
  mounted () {
  let labels = this.ageRangeData.map(item => item.age_label);
  let means = this.ageRangeData.map(item => item.mean);
  let sds = this.ageRangeData.map(item => item.sd);
 
  // Find the ageRangeData object for the desired age
  let ageData = this.ageRangeData.find(item => item.age >= item.age_low && item.age < item.age_high);
  //console.log(ageData);

  if (ageData) {
        new Chart(this.$refs.canvas.getContext('2d'), {
            type: 'line',
            data: {
            labels: labels,
            datasets: [
                {
                label: 'Raw Score',
                data: [{x: ageData.age_label, y: ageData.raw_score}],
                fill: false,
                borderColor: 'red',
                backgroundColor: 'red',
                pointRadius: 7,
                pointHoverRadius: 7
                },
                {
                label: 'Lower 2SD',
                data: means.map((mean, index) => mean - 2 * sds[index]),
                fill: false,
                borderColor: 'transparent',
                pointRadius: 0
                },
                {
                label: 'Lower SD',
                data: means.map((mean, index) => mean - sds[index]),
                fill: '-1',
                borderColor: 'transparent',
                backgroundColor: 'rgba(30, 81, 123, 0.1)',
                pointRadius: 0
                },
                {
                label: 'Mean',
                data: means,
                fill: '-1',
                borderColor: 'rgba(30, 81, 123, 1)',
                backgroundColor: 'rgba(30, 81, 123, 0.3)',
                pointBackgroundColor: 'rgba(30, 81, 123, 1)',
                },
                {
                label: 'Upper SD',
                data: means.map((mean, index) => mean + sds[index]),
                fill: '-1',
                borderColor: 'transparent',
                backgroundColor: 'rgba(30, 81, 123, 0.3)',
                pointRadius: 0
                },
                {
                label: 'Upper 2SD',
                data: means.map((mean, index) => mean + 2 * sds[index]),
                fill: '-1',
                borderColor: 'transparent',
                backgroundColor: 'rgba(30, 81, 123, 0.05)',
                pointRadius: 0
                },
                
            ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                y: {
                    ticks: {
                    callback: function(value, index, values) {
                        return value + ' mm';
                    }
                    }
                }
                }
            }
        });
    }
}
}
</script>
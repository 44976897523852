<template>
  <ion-page>
    <HeaderBar></HeaderBar>

    <ion-content overflow-scroll="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title> Disclaimer </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-text class="md-text">
            <p>
              The OphthoRuler app/website is in continuous development and is intended for research use.
              Use of the OphthoRuler app/website is subject to acceptance of the following disclaimer:
            </p>
            <p>
            No warranties of any kind whatsoever are made as to the results that
            You will obtain from relying upon the OphthoRuler app/website (or any information
            or content obtained by way of the OphthoRuler app/website), including but not
            limited to compliance with privacy laws or regulations or clinical
            care industry standards and protocols. Use of the OphthoRuler app/website is not
            a substitute for a health care provider’s standard practice or
            professional judgment. Any decision with regard to the appropriateness
            of treatment, or the validity or reliability of information or content
            made available by the OphthoRuler app/website, is the sole responsibility of the
            health care provider. Consequently, it is incumbent upon each user/health
            care provider to verify all medical history and treatment plans with
            each patient, and to validate measurements through clinicially appropriate standard of care methods.
            </p>
            <p>
            Under no circumstances and under no legal theory, whether tort
            (including negligence), contract, or otherwise, shall any Contributor,
            or anyone who distributes the OphthoRuler app/website as permitted by the
            license, be liable to You for any indirect, special, incidental,
            consequential damages of any character including, without limitation,
            damages for loss of goodwill, work stoppage, computer failure or
            malfunction, or any and all other damages or losses, of any nature
            whatsoever (direct or otherwise) on account of or associated with the
            use or inability to use the OphthoRuler app/website (including, without
            limitation, the use of information or content made available by the
            OphthoRuler app/website, all documentation associated therewith, and the failure
            of the OphthoRuler app/website to comply with privacy laws and regulations or
            clinical care industry standards and protocols), even if such party
            shall have been informed of the possibility of such damages.
            </p>
            <p>
            The creators, contributors, and supervisors of OphthoRuler are not responsible 
            for errors or omissions or for any consequences from application of the information 
            in this app/website and make no warranty, expressed or implied, with respect to the currency, 
            completeness, or accuracy of the contents of the OphthoRuler app/website.
            </p>
            <p>
            There are websites and resources linked within this app/website that are operated or created by 
            or for organisations, companies, or individuals not affiliated with OphthoRuler. These links are 
            provided as a convenience and for informational purposes only; they do not constitute an endorsement 
            or an approval by the reators, contributors, and supervisors of OphthoRuler. The reators, contributors, 
            and supervisors of OphthoRuler bear no responsibility for the accuracy, 
            security, legality, or content of the external sites linked in the OphthoRuler app/website or 
            for that of subsequent links.
            </p>
            <p>
              The above disclaimer is adapted in part from <a target="_blank" rel="noopener"
              href="https://openmrs.org/license/">OpenMRS</a>, distributed under the terms of the
              <a target="_blank" rel="noopener" href="https://www.mozilla.org/en-US/MPL/2.0/">
              Mozilla Public License, v. 2.0</a>
            </p>
          </ion-text>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang='js'>
import { defineComponent } from "vue";
import HeaderBar from "./HeaderBar.vue";
import {IonPage, IonContent, IonCardTitle, IonCardHeader, IonText, IonCard, IonCardContent} from '@ionic/vue';
export default defineComponent({
  name: "SideMenuInstall",
  components: {
    HeaderBar,
    IonPage,
    IonContent,
    IonCardTitle,
    IonCardHeader,
    IonText,
    IonCard,
    IonCardContent
  },
  data() {
    return {
      tippyTouchOptions: ['hold', 300],
      langvalue: 'en'
    }
  },
  mounted() {
    // disable context menu on nav icons (to not interfere with tooltips)
    [...document.querySelectorAll(".nav-icons")].forEach( el => 
    el.addEventListener('contextmenu', e => e.preventDefault())
    );
  },

  methods: {
    gotoPage(pageName) {
      this.$router.push({
        name: pageName,
      });
    },

  },
})
</script>

<style scoped>
.instructions-card {
  margin-top: 10px;
}
.header-title {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* Markdown text style */
 .md-text {
  font-size: 1rem;
}
.md-text p {
 font-size: 1rem;
 padding-top: 0rem;
 padding-bottom: 0.5rem;
 /* color:#333333; */
}
.dark .md-text p {
 color: #e2e2e2;
}
/* .md-text li {
 color: #333333;
} */
.dark .md-text li {
 color: #e2e2e2;
}
.md-text em {
 font-size: 0.9rem;
}
.md-text h2 {
 font-size: 1.1rem;
 font-weight: bold;
 hyphens: none;
}
.md-text h3 {
 font-size: 1.1rem;
 font-weight: bold;
 padding-top: 0.6rem;
 hyphens: none;
}
.md-text h3:first-child {
 padding-top: 0px;
}
.md-text h4 {
 font-size: 1rem;
 font-weight: 600;
 font-style: italic;
 padding-top: 0.6rem;
 padding-bottom: 0.4rem;
 hyphens: none;
}
.md-text ol {
 margin-top: 0px;
 padding-inline-start: 20px;
}
.md-text ul {
 margin-top: 0px;
 padding-inline-start: 20px;
}
.md-text ul li p {
   padding-top: 0px;
   padding-bottom: 0.2rem;
}
.md-text img {
   display: block;
   margin-left: auto;
   margin-right: auto;
   width: 95%;
   padding-top: 5px;
   padding-bottom: 5px;
}
.md-text a {
 text-decoration: none;
}
.md-text table {
 width: 100%
}
.md-text td {
 text-align: center;
}
</style>
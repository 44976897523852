import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue'
import SelectMorphometryVideo from '../views/SelectMorphometryVideo.vue'
import CompareToNorm from '../views/CompareToNorm.vue'
import SideMenuAboutUs from '../views/SideMenuAboutUs.vue'
import SideMenuAboutThisApp from '../views/SideMenuAboutThisApp.vue'
import SideMenuInstall from '../views/SideMenuInstall.vue'
import SideMenuDisclaimer from '../views/SideMenuDisclaimer.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/select-morphometry-video',
    name: 'SelectMorphometryVideo',
    component: SelectMorphometryVideo
  },
  {
    path: '/compare-to-norm',
    name: 'CompareToNorm',
    component: CompareToNorm
  },
  {
    path: '/about-us',
    name: 'SideMenuAboutUs',
    component: SideMenuAboutUs
  },
  {
    path: '/about-app',
    name: 'SideMenuAboutThisApp',
    component: SideMenuAboutThisApp
  },
  {
    path: '/install',
    name: 'SideMenuInstall',
    component: SideMenuInstall
  },
  {
    path: '/disclaimer',
    name: 'SideMenuDisclaimer',
    component: SideMenuDisclaimer
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

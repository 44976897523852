<template>
  <ion-page>
    <HeaderBar></HeaderBar>

    <ion-content overflow-scroll="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title> About this App </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-text class="md-text">
            <p>To learn more about the team behind OphthoRuler see the <a href="/about-us" target="_self">About Us</a> page.</p>
            <p><strong>App developed by:</strong> <a target="_blank" rel="noopener" href="https://jeremymoreau.com">Jeremy Moreau</a></p>
            <p>For questions, suggestions, or bug reports about this app send an email to:</p>
            <p> <a class="obfs-m" data-part1="jeremy.moreau" data-part2="ucalgary.ca" data-part3="ca">Jeremy Moreau (tap to reveal email)</a></p>

            <h3>Open Source Acknowledgments</h3>    
            <ul>
            <li><a target="_blank" rel="noopener" href="https://github.com/math-utils/area-polygon">Area Polygon (MIT)</a></li>
            <li><a target="_blank" rel="noopener" href="https://github.com/ionic-team/ionic-framework">Ionic Framework (MIT)</a></li>
            <li><a target="_blank" rel="noopener" href="https://github.com/yyx990803/register-service-worker">register-service-worker (MIT)</a></li>
            <li><a target="_blank" rel="noopener" href="https://github.com/josdejong/mathjs">mathjs (Apache 2.0)</a></li>
            <li><a target="_blank" rel="noopener" href="https://github.com/google/mediapipe">MediaPipe (Apache 2.0)</a></li>
            <li><a target="_blank" rel="noopener" href="https://github.com/tensorflow/tfjs">TensorFlow.js (Apache 2.0)</a></li>
            <li><a target="_blank" rel="noopener" href="https://github.com/vuejs/vue-next">Vue (MIT)</a></li>
            <!-- <li><a target="_blank" rel="noopener" href="https://github.com/MatteoGabriele/vue-gtag">Vue-gtag (MIT)</a></li> -->
            <li><a target="_blank" rel="noopener" href="https://github.com/vuejs/vue-router-next">Vue Router (MIT)</a></li>
            <!-- <li><a target="_blank" rel="noopener" href=""></a></li> -->
            </ul>
            <h3>Icon Acknowledgments</h3>
            <ul>
              <li><a target="_blank" rel="noopener" href="https://github.com/ionic-team/ionicons">Ionicicons (MIT)</a></li>
            </ul>
          </ion-text>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang='js'>
import { defineComponent } from "vue";
import HeaderBar from "./HeaderBar.vue";
import {IonPage, IonContent, IonCardTitle, IonCardHeader, IonText, IonCardContent, IonCard} from '@ionic/vue';
export default defineComponent({
  name: "SideMenuInstall",
  components: {
    HeaderBar,
    IonPage,
    IonContent,
    IonCardTitle,
    IonCardHeader,
    IonText,
    IonCardContent,
    IonCard  
  },
  data() {
    return {
      tippyTouchOptions: ['hold', 300],
      langvalue: 'en'
    }
  },
  mounted() {
    // disable context menu on nav icons (to not interfere with tooltips)
    [...document.querySelectorAll(".nav-icons")].forEach( el => 
    el.addEventListener('contextmenu', e => e.preventDefault())
    );
  },
  updated() {
    this.deObfsMail()
  },

  methods: {
    gotoPage(pageName) {
      this.$router.push({
        name: pageName,
      });
    },

    // Obfs addresses (tap to reveal)
    async deObfsMail() {
        const obfsList = document.getElementsByClassName("obfs-m")
        for (const obfsItem of obfsList) {
            obfsItem.onclick = function() {
                const outputVal = obfsItem.dataset.part1 + "@" + obfsItem.dataset.part2 + "." + obfsItem.dataset.part3
                obfsItem.href = "mai" + "lto:" + outputVal
                obfsItem.innerHTML = outputVal
            }
        }
    }
  },
})
</script>

<style scoped>
.instructions-card {
  margin-top: 10px;
}
.header-title {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* Markdown text style */
 .md-text {
  font-size: 1rem;
}
.md-text p {
 font-size: 1rem;
 padding-top: 0rem;
 padding-bottom: 0.5rem;
 /* color:#333333; */
}
.dark .md-text p {
 color: #e2e2e2;
}
.md-text li {
 color: #333333;
}
.dark .md-text li {
 color: #e2e2e2;
}
.md-text em {
 font-size: 0.9rem;
}
.md-text h2 {
 font-size: 1.1rem;
 font-weight: bold;
 hyphens: none;
}
.md-text h3 {
 font-size: 1.1rem;
 font-weight: bold;
 padding-top: 0.6rem;
 hyphens: none;
}
.md-text h3:first-child {
 padding-top: 0px;
}
.md-text h4 {
 font-size: 1rem;
 font-weight: 600;
 font-style: italic;
 padding-top: 0.6rem;
 padding-bottom: 0.4rem;
 hyphens: none;
}
.md-text ol {
 margin-top: 0px;
 padding-inline-start: 20px;
}
.md-text ul {
 margin-top: 0px;
 padding-inline-start: 20px;
}
.md-text ul li p {
   padding-top: 0px;
   padding-bottom: 0.2rem;
}
.md-text img {
   display: block;
   margin-left: auto;
   margin-right: auto;
   width: 95%;
   padding-top: 5px;
   padding-bottom: 5px;
}
.md-text a {
 text-decoration: none;
}
.md-text table {
 width: 100%
}
.md-text td {
 text-align: center;
}
</style>